import { envConfig } from '@/v1/constants'
import * as Sentry from '@sentry/react'
import { handleError } from '@v1/utils'
import { isNetworkError } from '@v1/utils/isNetworkError'
import { utmInterceptor } from '@v1/utils/utmInterceptor'
import axios, { AxiosResponse } from 'axios'

export const apiClient = axios.create({
  baseURL: String(envConfig.VITE_APP_REGION_CONFIG_API_URL || ''),
})

apiClient.interceptors.request.use(utmInterceptor)

apiClient.interceptors.response.use(
  (response: AxiosResponse<{ ret: number; msg: string }>) => {
    if (response.data.ret !== 0) {
      throw new Error(response.data.msg)
    }

    return response
  },
  error => {
    const { response } = error

    if (!isNetworkError(error)) {
      Sentry.withScope(scope => {
        // group errors together based on their request and response
        scope.setFingerprint([
          error.request.method,
          response.config.method,
          String(error.status),
        ])

        handleError(error)
      })
    }

    return Promise.reject(error)
  }
)
