/* eslint-disable fp/no-mutation */

import { getRuntimeConfig } from '@/configs'
import { polyfillI18nTemplatePlaceholder } from '@v1/utils/polyfillI18nTemplatePlaceholder'
import i18n from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

const DEFAULT_NS = 'translations'

const envConfig = await getRuntimeConfig()

const languageModules =
  envConfig.VITE_APP_THEME === 'uncleD'
    ? import.meta.glob('./*-IN/*.json')
    : import.meta.glob('./*/*.json')

const fallbackLang = envConfig.VITE_APP_THEME === 'uncleD' ? 'en-IN' : 'en'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(
    resourcesToBackend(async (language, namespace, callback) => {
      try {
        const matchPath = `./${
          language === 'en' ? fallbackLang : language
        }/${namespace}.json`

        const importFn =
          languageModules[matchPath] ||
          languageModules[
            `./${
              language === 'en' ? fallbackLang : language
            }/${DEFAULT_NS}.json`
          ] ||
          languageModules[`./${fallbackLang}/${namespace}.json`]

        if (!importFn) {
          return callback(null, {})
        }

        const result = (await importFn()) as Record<string, string>

        return callback(
          null,
          polyfillI18nTemplatePlaceholder(
            result.default as unknown as Record<string, string>
          )
        )
      } catch (e) {
        return callback(e as Error, null)
      }
    })
  )
  .init({
    lng: fallbackLang,
    fallbackLng: fallbackLang,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    defaultNS: DEFAULT_NS,
    ns: ['translations'],
    react: {
      // Fire rerender on language loaded
      bindI18n: 'added loaded languageChanged',
      bindI18nStore: 'added',
    },
  })
