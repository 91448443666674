import { QueryStringKey } from '@v1/constants/queryStringKey'
import { createBrowserHistory } from 'history'

import { createPreserveQueryHistory } from './createPreserveQueryHistory'

// preserve query param on route change
export const browserHistory = createPreserveQueryHistory(
  createBrowserHistory,
  Object.values(QueryStringKey)
)()
