import { useQuery } from '@tanstack/react-query'
import { CacheKey } from '@v1/constants'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { formConfigsApi } from '@v1/services/dcrm'
import { FormConfigSkips } from '@v1/types'
import { handleError, isFormSkip } from '@v1/utils'
import { isNil } from 'ramda'
import { useEffect, useMemo } from 'react'

import { useModal } from './useModal'
import {
  useI18n,
  useQueryParam,
  useRegion,
  useRegistrationInfo,
  useSetLoading,
  useTempFormValue,
} from '.'

export const useFormConfig = () => {
  const { cityId } = useRegion()
  const { openUnexpected } = useModal()
  const { tempFormValues } = useTempFormValue()
  const params = useQueryParam()
  const fleetInvitationCode = params.get(QueryStringKey.FLEET_INV_CODE)
  const isFleet = !isNil(fleetInvitationCode)
  const { hlang } = useI18n()
  const { registrationInfo } = useRegistrationInfo()
  const { vehicle_type_id } = registrationInfo || {}
  const setLoading = useSetLoading()
  const cityVal =
    cityId > 0 ? cityId : tempFormValues?.cityId ? tempFormValues.cityId : 0

  const vehicleTypeId = vehicle_type_id || tempFormValues.vehicleTypeId || 0

  const {
    data: formConfigs,
    isLoading: isLoadingFormConfig,
    ...rest
  } = useQuery(
    [CacheKey.FORM_CONFIGS, vehicleTypeId, cityId, hlang],
    () => formConfigsApi.get({ cityId: cityVal, vehicleTypeId, isFleet }),
    {
      onError: e => {
        handleError(e)
        openUnexpected()
      },
    }
  )

  const formConfigSkips: FormConfigSkips | null = useMemo(() => {
    if (!formConfigs) return null
    const personalInfoConfig = formConfigs?.personalInfo
    const vehicleInfoConfig = formConfigs?.vehicleInfo
    const additionalInfoConfig = formConfigs?.additionalInfo

    return {
      isPersonalInfoSkipped: isFormSkip(personalInfoConfig),
      isVehicleInfoSkipped: isFormSkip(vehicleInfoConfig),
      isAdditionalInfoSkipped: isFormSkip(additionalInfoConfig),
      isTrainingSkipped: false, // hard-coded
    }
  }, [formConfigs])

  useEffect(() => {
    setLoading?.({
      isLoadingFormConfig,
    })
  }, [isLoadingFormConfig, setLoading])

  return {
    formConfigs,
    formConfigSkips,
    isLoading: isLoadingFormConfig,
    ...rest,
  }
}
