import '@v1/utils/dayjs'
import '@v1/utils/nativeBridge'
import 'react-toastify/dist/ReactToastify.css'
import 'ress'

import './locales'

import probes from '@hll/sec-sign-sdk'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { isDev } from '@v1/constants'
import { UseRegionProvider } from '@v1/hooks/useRegion'
import {
  UseRemoteConfigProvider,
  useRemoteConfig,
} from '@v1/hooks/useRemoteConfig'
import { DriverCRMWebAPIError } from '@v1/services/dcrm/errors'
import { browserHistory } from '@v1/utils/browserHistory'
import { isNetworkError } from '@v1/utils/isNetworkError'
import React, { Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Router as ReactRouter } from 'react-router-dom'

import { PageLoading, PurePageLoading } from './v1/components/PageLoading'
import { UseLoadingProvider } from './v1/hooks'
import { getRuntimeConfig } from './configs'

const config = await getRuntimeConfig()

probes.init({
  debug: false,
  allowList: [config.VITE_APP_DCRM_API_BASE_URL],
})

TagManager.initialize({
  gtmId: String(config.VITE_APP_GTM_ID || ''),
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // lower refetch frequency that blocks interaction
      retry: false,
    },
  },
})

if (!isDev) {
  Sentry.init({
    dsn: String(config.VITE_APP_SENTRY_DSN || ''),
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation:
          Sentry.reactRouterV5Instrumentation(browserHistory),
      }),
    ],
    tracesSampleRate: 0.01,
    release: `global-driver-registration@${__APP_VERSION__}`,
    environment: config.VITE_APP_IDC,
    beforeSend: (event, hint) => {
      const error = hint?.originalException
      if (error) {
        if (typeof error === 'string') {
          return event
        }

        // v1 only
        if (
          (error instanceof DriverCRMWebAPIError && !error.isSentryError) ||
          isNetworkError(error)
        ) {
          return null
        }

        if (
          error instanceof DriverCRMWebAPIError &&
          !error.shouldNotifySentry
        ) {
          return null
        }
      }

      return event
    },
  })
}

const V1Main = lazy(() => import('./v1/main'))

const FlaggedApp = () => {
  const { initialized } = useRemoteConfig()
  if (!initialized) return <PurePageLoading isLoading />
  return <V1Main />
}

const AppWithWrappers = Sentry.withProfiler(() => (
  <Suspense fallback={<PurePageLoading isLoading />}>
    <ReactRouter history={browserHistory}>
      <UseLoadingProvider>
        <QueryClientProvider client={queryClient}>
          <UseRegionProvider>
            <UseRemoteConfigProvider>
              <FlaggedApp />
              <PageLoading />
            </UseRemoteConfigProvider>
          </UseRegionProvider>
        </QueryClientProvider>
      </UseLoadingProvider>
    </ReactRouter>
  </Suspense>
))

const root = document.getElementById('root')
if (root) {
  createRoot(root).render(<AppWithWrappers />)
}

console.info(
  'Current App Version: ',
  import.meta.env.VAN_GIT_COMMIT_HASH,
  config?.VITE_APP_IDC
)
