import { UrlString } from '@v1/types'
import { shallowRemoveEmptyProps } from '@v1/utils'

import { apiClient } from './apiClient'

type CityConfig = {
  id: string
  name_en: string
  name: string
  enable_register: boolean
}

type RegistrationConfigs = {
  city_list: CityConfig[]
  links: {
    help_link: UrlString
    terms_and_conditions: UrlString
    privacy_policy: UrlString
    fleet_help_link: UrlString
    fleet_privacy_policy: UrlString
    fleet_terms_and_conditions: UrlString
  }
}

const get = async ({
  cityId,
  marketId,
  hlang,
  deviceId,
}: {
  cityId?: string | number
  marketId?: string | number
  hlang?: string
  deviceId?: string
}) => {
  const params = shallowRemoveEmptyProps({
    city_id: cityId,
    hcountry: marketId,
    hlang,
    device_id: deviceId,
  })

  const { data } = await apiClient.get<RegistrationConfigs>(
    '/registration-configs',
    {
      params,
    }
  )

  return data
}

export const registrationConfigApi = {
  get,
}
