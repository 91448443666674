import { getAnalytics, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getRemoteConfig } from 'firebase/remote-config'
import { envConfig, isProduction } from '../constants'

export const firebaseApp = initializeApp({
  apiKey: String(envConfig.VITE_APP_FIREBASE_API_KEY || ''),
  authDomain: String(envConfig.VITE_APP_FIREBASE_AUTH_DOMAIN || ''),
  projectId: String(envConfig.VITE_APP_FIREBASE_PROJECT_ID || ''),
  storageBucket: String(envConfig.VITE_APP_FIREBASE_STORAGE_BUCKET || ''),
  messagingSenderId: String(
    envConfig.VITE_APP_FIREBASE_MESSAGING_SENDER_ID || ''
  ),
  appId: String(envConfig.VITE_APP_FIREBASE_APP_ID || ''),
})

export const remoteConfig = getRemoteConfig(firebaseApp)

// eslint-disable-next-line fp/no-mutation
remoteConfig.settings.minimumFetchIntervalMillis = isProduction
  ? 3600000
  : 600000

export const setUserProperty = (userData: {
  market: string
  phonePrefix?: string
  platform?: string
  hlang: string
}) => {
  const analytics = getAnalytics(firebaseApp)

  setUserProperties(analytics, {
    mode: envConfig.VITE_APP_IDC,
    platform: userData.platform,
    market: userData.market,
    phonePrefix: userData.phonePrefix,
    hlang: userData.hlang,
  })
}
