import { ViteAppIDC } from '../src/v1/constants/dataCentre'

export default {
  NODE_ENV: 'development',
  VITE_APP_IDC: ViteAppIDC.SG_PRE_UNCLED,
  VITE_APP_THEME: 'uncleD',
  VITE_APP_DCRM_API_BASE_URL: 'https://sg-dcrm-api-pre.lalamove.com/web-api',
  VITE_APP_LAFORME_API_BASE_URL:
    'https://sg-laforme-api-pre.lalamove.com/api/v1/published-schemas/driver-registration-form',
  VITE_APP_REGION_CONFIG_API_URL:
    'https://region-configuration-pre.lalamove.com',
  VITE_APP_DRIVER_REG_FILE_API_URL: 'https://sg-uimg-pre.lalamove.com',
  VITE_APP_ODOKO_API_URL: 'https://sg-odoko-api-pre.lalamove.com',
  VITE_APP_APPSFLYER_URL: 'uncle-delivery.com',

  VITE_APP_ODOKO_CLIENT_ID: '9WG3YdGmQ_1ZuP659S7MB',

  VITE_APP_FIREBASE_API_KEY: 'AIzaSyAfwBgA9acH8RauSLEJ9H0tg4DDLdXcbLY',
  VITE_APP_FIREBASE_AUTH_DOMAIN: 'global-driver-registration.firebaseapp.com',
  VITE_APP_FIREBASE_PROJECT_ID: 'global-driver-registration',
  VITE_APP_FIREBASE_STORAGE_BUCKET: 'global-driver-registration.appspot.com',
  VITE_APP_FIREBASE_MESSAGING_SENDER_ID: '414089515562',
  VITE_APP_FIREBASE_APP_ID: '1:414089515562:web:8ad7318e2cfb46c75c2953',

  VITE_APP_LUNA_UPLOAD_API_BASE_URL:
    'https://sg-luna-pre.lalamove.com/luna/api',
  VITE_APP_LUNA_PUBLIC_BASE_URL:
    'https://luna-public.s3-ap-southeast-1.amazonaws.com',

  VITE_APP_FIREBASE_ANALYTICS_API_KEY:
    'AIzaSyAUfJn6oT6r4SIRmbP7SrY1SWB_wWGnljY',
  VITE_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN:
    'uncle-delivery-development.firebaseapp.com',
  VITE_APP_FIREBASE_ANALYTICS_PROJECT_ID: 'uncle-delivery-development',
  VITE_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET:
    'uncle-delivery-development.appspot.com',
  VITE_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID: '947884015682',
  VITE_APP_FIREBASE_ANALYTICS_APP_ID:
    '1:947884015682:web:3f986a44568505d31b2ddb',
  VITE_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID: 'G-L3SPJWXQ4P',

  VITE_SENTRY_ORG: 'lalamove-tech',
  VITE_SENTRY_PROJECT: 'global-driver-registration',
  VITE_APP_SENTRY_DSN:
    'https://3b4c9c4e3f28402ab28e996910238c0a@o562705.ingest.sentry.io/6189713',

  VITE_APP_SENSORS_SERVER_URL:
    'https://uba.prd.bom.uncle-delivery.com/sa?project=production',

  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_COUNT: '20',
  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_INTERVAL: '1',

  VITE_APP_GTM_ID: 'GTM-MC7ZZ5K',
  VITE_APP_BRANCH_KEY: 'key_test_de9CrUed40qEuirybDDNbidbAydYq9pU',
  VITE_APP_HCAPTCHA_SITE_KEY: '8f150ad4-7dc3-4590-8eeb-65cf9ef4b76f',

  VITE_APP_DOWNLOAD_DRIVER_APP_URL: 'https://www.uncle-delivery.com',

  VITE_APP_APPSFLYER_WEB_APP_ID: 'a4dfa785-0837-4173-bf0e-b71be553efda',
  VITE_APP_APPSFLYER_BANNER_KEY: '4069a577-2daf-41b3-b457-ea8a745bfc5d',
}
