import { useQuery } from '@tanstack/react-query'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { vehicleTypeApi } from '@v1/services/dcrm/vehicleTypes'

import { useQueryParam } from '.'

export const useVehicleTypes = (cityId: number) => {
  const params = useQueryParam()
  const deviceId = params.get(QueryStringKey.DEVICE_ID) || undefined
  return useQuery(
    ['vehicle-types', cityId, deviceId],
    () =>
      cityId
        ? vehicleTypeApi.get({
            cityId: cityId,
            deviceId,
          })
        : [],
    { enabled: Boolean(cityId) }
  )
}
