import { mapObjIndexed, replace } from 'ramda'

type I18nSource = { [key: string]: I18nSource | string }

export const polyfillI18nTemplatePlaceholder = (src: I18nSource): I18nSource =>
  mapObjIndexed(value => {
    if (typeof value === 'string') {
      return replace(/}/g, '}}', replace(/{/g, '{{', value))
    }

    return polyfillI18nTemplatePlaceholder(value)
  }, src)
