import { useQueryClient } from '@tanstack/react-query'
import {
  CacheKey,
  LocalStorageKey,
  Path,
  PathError,
  RegistrationStatus,
  RemoteConfigKey,
  StepType,
  getStepFromPath,
} from '@v1/constants'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { useVirtualTraining } from '@v1/hooks/useVirtualTraining'
import { RegistrationUpdateParam } from '@v1/services/dcrm'
import { PhoneNumber } from '@v1/types'
import { getRedirectRoute } from '@v1/utils/getRedirectRoute'
import constate from 'constate'
import qs from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { useUpdateEffect } from 'react-use'

import { useAuth } from './useAuth'
import { useOnsiteTraining } from './useOnsiteTraining'
import {
  useFleet,
  useFormConfig,
  useI18n,
  useLocalStorageRT,
  useQueryParam,
  useRegion,
  useRegistrationInfo,
  useRegistrationMutation,
  useRemoteConfig,
  useTempFormValue,
} from '.'

export const [UseRegistrationProvider, useRegistration] = constate(() => {
  const query = useQueryParam()
  const queryClient = useQueryClient()
  const { marketId, cityId, setCityId, setMarketId } = useRegion()
  const { hlang } = useI18n()
  const {
    isLoggedIn,
    logout,
    token,
    isRegistrationInitialized,
    setRegistrationInitialized,
  } = useAuth()

  const { fleetInfo, isFleet } = useFleet()
  const {
    formConfigSkips,
    isLoading: isLoadingFormConfig,
    isError: isFormConfigError,
  } = useFormConfig()

  const {
    isAvailable: isVirtualTrainingAvailable,
    isLoading: isLoadingVirtualTraining,
    isError: isVirtualTrainingError,
  } = useVirtualTraining()

  const {
    isAvailable: isOnSiteTrainingAvailable,
    isLoading: isLoadingOnsiteTraining,
    isError: isOnsiteTrainingError,
  } = useOnsiteTraining()

  const {
    registrationInfo,
    isFetching: isRegInfoFetching,
    isSuccess: isRegInfoSuccess,
  } = useRegistrationInfo()

  const history = useHistory()
  const location = useLocation<{ from: Path }>()
  const [rejectionAcknowledgements] = useLocalStorageRT<PhoneNumber[]>(
    LocalStorageKey.REJECTION_ACKNOWLEDGED_BY_PHONE
  )

  const { tempFormValues, setTempFormValues } = useTempFormValue()
  const tempCityAndVehicleFilled =
    tempFormValues.cityId && tempFormValues.vehicleTypeId

  const { should, initialized: initializedRemoteConfig } = useRemoteConfig()
  const usePersonalDriverFlow = should(RemoteConfigKey.USE_PERSONAL_DRIVER_FLOW)

  const iFrame = query.get(QueryStringKey.IFRAME) || undefined
  const htzone = query.get(QueryStringKey.HTZONE) || undefined
  const clientType = query.get(QueryStringKey.CLIENT_TYPE) || undefined
  const isInIFrame = Number(iFrame) === 1
  const browserLocation = window.location
  const { t } = useTranslation()
  const { createRegistration, patchRegistration, isRegistrationMutating } =
    useRegistrationMutation()

  const { totalSteps, stepNumberMap } = useMemo(() => {
    if (!formConfigSkips) return {}
    const stepSkipMap = {
      [StepType.CREATE_ACCOUNT]: false,
      [StepType.PERSONAL_INFO]: formConfigSkips.isPersonalInfoSkipped,
      [StepType.VEHICLE_INFO]: formConfigSkips.isVehicleInfoSkipped,
      [StepType.TRAINING]: formConfigSkips.isTrainingSkipped,
      [StepType.ADDITIONAL_INFO]: formConfigSkips.isAdditionalInfoSkipped,
    }

    const stepEntriesMemo = Object.entries(stepSkipMap)
      .filter(([, isSkipped]) => !isSkipped)
      .map(([key], index) => [key, index + 1])

    const stepNumberMapMemo: Record<StepType, number> =
      Object.fromEntries(stepEntriesMemo)

    return {
      totalSteps: stepEntriesMemo.length,
      stepNumberMap: stepNumberMapMemo,
    }
  }, [formConfigSkips])

  const currentStepNumber = useMemo(() => {
    const currentPath = location.pathname as Path
    const step = getStepFromPath(currentPath)
    if (!step || !stepNumberMap) return 1
    return stepNumberMap[step]
  }, [stepNumberMap, location.pathname])

  const commit = useCallback(
    async (newRegInfo?: RegistrationUpdateParam) => {
      if (!registrationInfo) return
      if (newRegInfo) {
        patchRegistration({
          changes: newRegInfo,
          registrationId: registrationInfo.registration_id,
        })
      } else {
        queryClient.invalidateQueries([CacheKey.REGISTRATION])
      }
    },
    [patchRegistration, queryClient, registrationInfo]
  )

  // update region value or logout on market id change
  useEffect(() => {
    if (fleetInfo) {
      // always trust fleet info
      setMarketId(fleetInfo.country_id)
      setCityId(fleetInfo.city_id)
      return
    }

    if (registrationInfo) {
      if (registrationInfo.country_id !== marketId) {
        // always trust URL/local storage
        logout()
        return
      }

      setCityId(registrationInfo.city_id)
    } else {
      setCityId(0)
    }
  }, [
    registrationInfo,
    setCityId,
    fleetInfo,
    logout,
    setMarketId,
    marketId,
    cityId,
  ])

  // initialize registration flow,
  useUpdateEffect(() => {
    /**
        has reg info
          ? rejected
            ? rejectionAcked
              ? createRegInfo, refetch
              : do nothing
            : do nothing
          : createRegInfo, refetch
       */

    if (
      !isLoggedIn ||
      isRegistrationInitialized ||
      isRegInfoFetching ||
      !isRegInfoSuccess ||
      isRegistrationMutating
    ) {
      return
    }

    if (registrationInfo) {
      const isRejected = registrationInfo.status === RegistrationStatus.REJECTED

      const isRejectionAcknowledged = (
        rejectionAcknowledgements || []
      ).includes(registrationInfo.phone_number)

      // allow rejected personal driver reg fleet driver
      const isFleetInvitedButPersonalRejected =
        isFleet && registrationInfo.fleet_id === 0 && isRejected

      const shouldCreateNewReg =
        isFleetInvitedButPersonalRejected ||
        (isRejected && isRejectionAcknowledged)

      const isResumable = ![
        RegistrationStatus.APPROVED,
        RegistrationStatus.REJECTED,
        RegistrationStatus.TRAINING_FAILED,
      ].includes(registrationInfo.status)

      if (
        !shouldCreateNewReg &&
        tempCityAndVehicleFilled &&
        isResumable &&
        location.state?.from === Path.LANDING
      ) {
        toast.info(t('NewRegistration.resume_flow'))
      }

      if (shouldCreateNewReg) {
        createRegistration()
      } else {
        setTempFormValues({})
        setRegistrationInitialized(true)
      }
    } else {
      createRegistration()
    }
  }, [
    isRegInfoFetching,
    isRegInfoSuccess,
    registrationInfo,
    isRegistrationMutating,
    isRegistrationInitialized,
    isLoggedIn,
  ])

  // logout if fleetInfo misaligned with current session
  useEffect(() => {
    if (!fleetInfo || !registrationInfo) return
    const isMatch = fleetInfo.phone_number === registrationInfo.phone_number
    if (!isMatch) {
      logout()
      return
    }
  }, [fleetInfo, logout, registrationInfo])

  // block access to fleet if no invitation
  useEffect(() => {
    if (!initializedRemoteConfig) return
    if (!isFleet && !usePersonalDriverFlow) {
      history.replace(Path.ERROR, {
        errorType: PathError.FLEET_INVITATION_CODE_MISSING,
      })
    }
  }, [isFleet, usePersonalDriverFlow, history, initializedRemoteConfig])

  // redirect path based on regInfo and other factors
  useUpdateEffect(() => {
    if (
      !formConfigSkips ||
      !isLoggedIn ||
      !registrationInfo ||
      !isRegistrationInitialized ||
      isLoadingVirtualTraining ||
      isLoadingOnsiteTraining ||
      isLoadingFormConfig ||
      isRegistrationMutating ||
      isRegInfoFetching
    ) {
      return
    }

    if (isFormConfigError || isVirtualTrainingError || isOnsiteTrainingError) {
      setRegistrationInitialized(true) // cannot fetch core info, give up
      return
    }

    const path = getRedirectRoute({
      formConfigSkips,
      registrationInfo,
      currentRegStatus: registrationInfo.status,
      isOnSiteTrainingAvailable,
      isVirtualTrainingAvailable,
      isFleet,
    })

    if (!isInIFrame) {
      if (path !== location.pathname) history.replace(path)
    } else {
      const redirectUrl = browserLocation.origin.concat(
        path,
        qs.stringify(
          {
            hcountry: registrationInfo.country_id,
            hlang: hlang,
            htzone: htzone,
            client_type: clientType,
            token: token,
          },
          { addQueryPrefix: true }
        )
      )

      logout()
      window.open(redirectUrl, '_top')
    }
  }, [
    isRegistrationInitialized,
    isFleet,
    isLoggedIn,
    formConfigSkips,
    registrationInfo,
    isOnSiteTrainingAvailable,
    isVirtualTrainingAvailable,
    isLoadingOnsiteTraining,
    isLoadingVirtualTraining,
    isLoadingFormConfig,
    isRegistrationMutating,
    isRegInfoFetching,
    isFormConfigError,
    isVirtualTrainingError,
    isOnsiteTrainingError,
    isInIFrame,
    setRegistrationInitialized,
    token,
    logout,
  ])

  return {
    currentStepNumber,
    totalSteps,
    stepNumberMap,
    commit,
  }
})
