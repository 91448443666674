import { ViteAppIDC } from '../src/v1/constants/dataCentre'

export default {
  NODE_ENV: 'production',
  VITE_APP_IDC: ViteAppIDC.BR_PROD,
  VITE_APP_THEME: 'lalamove',
  VITE_APP_DCRM_API_BASE_URL: 'https://br-dcrm-api.lalamove.com/web-api',
  VITE_APP_LAFORME_API_BASE_URL:
    'https://sg-laforme-api.lalamove.com/api/v1/published-schemas/driver-registration-form',
  VITE_APP_REGION_CONFIG_API_URL: 'https://region-configuration.lalamove.com',
  VITE_APP_DRIVER_REG_FILE_API_URL: 'https://br-uimg.lalamove.com',
  VITE_APP_ODOKO_API_URL: 'https://br-odoko-api.lalamove.com',
  VITE_APP_APPSFLYER_URL: 'lalamove.com',

  VITE_APP_ODOKO_CLIENT_ID: 'hNb-wv_ywNZOelUETOF0l',

  VITE_APP_FIREBASE_API_KEY: 'AIzaSyAfwBgA9acH8RauSLEJ9H0tg4DDLdXcbLY',
  VITE_APP_FIREBASE_AUTH_DOMAIN: 'global-driver-registration.firebaseapp.com',
  VITE_APP_FIREBASE_PROJECT_ID: 'global-driver-registration',
  VITE_APP_FIREBASE_STORAGE_BUCKET: 'global-driver-registration.appspot.com',
  VITE_APP_FIREBASE_MESSAGING_SENDER_ID: '414089515562',
  VITE_APP_FIREBASE_APP_ID: '1:414089515562:web:8ad7318e2cfb46c75c2953',

  VITE_APP_LUNA_UPLOAD_API_BASE_URL: 'https://br-luna.lalamove.com/luna/api',
  VITE_APP_LUNA_PUBLIC_BASE_URL:
    'https://luna-baxi-public.s3.sa-east-1.amazonaws.com',

  VITE_APP_FIREBASE_ANALYTICS_API_KEY:
    'AIzaSyAwKxpwH5cJMQdz8HUSFoaAwd_ej1pJsOQ',
  VITE_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN: 'lalamoveglobal.firebaseapp.com',
  VITE_APP_FIREBASE_ANALYTICS_PROJECT_ID: 'lalamoveglobal',
  VITE_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET: 'lalamoveglobal.appspot.com',
  VITE_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID: '884806843951',
  VITE_APP_FIREBASE_ANALYTICS_APP_ID:
    '1:884806843951:web:2ec88b09e8166c02fad0da',
  VITE_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID: 'G-9CGLZHTQLY',

  VITE_SENTRY_ORG: 'lalamove-tech',
  VITE_SENTRY_PROJECT: 'global-driver-registration',
  VITE_APP_SENTRY_DSN:
    'https://3b4c9c4e3f28402ab28e996910238c0a@o562705.ingest.sentry.io/6189713',

  VITE_APP_SENSORS_SERVER_URL:
    'https://uba.huolalamove.net/sa?project=production',

  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_COUNT: '20',
  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_INTERVAL: '1',

  VITE_APP_GTM_ID: 'GTM-N2JSKRT',
  VITE_APP_BRANCH_KEY: 'key_live_ab3n82rLiTSkshosEBoekammqBdXGjH8',
  VITE_APP_HCAPTCHA_SITE_KEY: '9573d33b-2723-4d6f-a5ba-65e86ba259e9',

  VITE_APP_DOWNLOAD_DRIVER_APP_URL: 'https://www.lalamove.com/driver-app/us',

  VITE_APP_APPSFLYER_WEB_APP_ID: 'a33d6678-a2d2-473b-8e20-900785dd63fc',
  VITE_APP_APPSFLYER_BANNER_KEY: 'b1ffbaf5-760a-4f32-a51b-60fed327fce4',
}
