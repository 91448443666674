import { ApiErrors } from '@v1/constants/errors'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'

import { DriverCRMWebAPIError } from './driverCRMWebAPIError'

export type OtpLimitErrorDetails = {
  rateLimitInDayReached: boolean
  rateLimitInMinReached: boolean
}
export class DriverCRMWebAPIOtpExceedLimitError extends DriverCRMWebAPIError {
  override code: ApiErrors.EXCEED_LIMIT
  override details: OtpLimitErrorDetails
  override message: string

  constructor({
    message,
    code = ApiErrors.EXCEED_LIMIT,
    details,
    ...rest
  }: {
    code?: ApiErrors.EXCEED_LIMIT
    message: string
    details: OtpLimitErrorDetails
    shouldNotifySentry?: boolean | undefined
  }) {
    super({
      message,
      code,
      details,
      ...rest,
    })

    // eslint-disable-next-line fp/no-mutation
    this.message = message
    // eslint-disable-next-line fp/no-mutation
    this.code = code
    // eslint-disable-next-line fp/no-mutation
    this.details = details
  }
}

export const handleDriverCRMWebAPIOtpExceedLimitError = ({
  e,
  t,
}: {
  e: DriverCRMWebAPIOtpExceedLimitError
  t: TFunction
}) => {
  const { rateLimitInDayReached, rateLimitInMinReached } = e.details
  if (rateLimitInDayReached) {
    toast.error(t('Error.rate_limit_reach_day'))
  }

  if (rateLimitInMinReached) {
    toast.info(t('Error.rate_limit_reach_min'))
  }
}

export const isDriverCRMWebAPIOtpExceedLimitError = (
  e: any
): e is DriverCRMWebAPIOtpExceedLimitError =>
  e instanceof DriverCRMWebAPIOtpExceedLimitError
