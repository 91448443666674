import { Path } from '.'

export enum TrainingType {
  NOT_SET = 0,
  ONSITE = 1,
  VIRTUAL = 2,
}

export const trainingTypePathMap = {
  [TrainingType.NOT_SET]: Path.TRAINING_SELECTION,
  [TrainingType.ONSITE]: Path.ONSITE_TRAINING,
  [TrainingType.VIRTUAL]: Path.VIRTUAL_TRAINING,
}

export const trainingTypeNameMap = {
  [TrainingType.NOT_SET]: 'not-set',
  [TrainingType.ONSITE]: 'in-person',
  [TrainingType.VIRTUAL]: 'online',
}
