import { Path } from '@v1/constants'
import constate from 'constate'
import { useLocation } from 'react-router'
import { usePreviousDistinct } from 'react-use'

export const [UsePrevPathProvider, usePrevPath] = constate(() => {
  const routeLocation = useLocation()

  return usePreviousDistinct<Path>(
    routeLocation.pathname as Path,
    (prev, next) => prev === next
  )
})
