import {
  DriverRegNavLeftButtonType,
  DriverRegNavRightButtonType,
} from '@hll/driver-webview-bridge-js'

export enum Path {
  ADDITIONAL_INFO = '/additional',
  BASIC_INFO = '/account',
  COMPLETED_STEPS = '/steps-status',
  VERIFIED = '/verified',
  PERSONAL_INFO = '/personal',
  LANDING = '/join',
  OTP_VERIFICATION = '/phone-verification',
  REJECTED = '/reject',
  RESUBMIT = '/resubmit-documents',
  TRAINING_SELECTION = '/training',
  VIRTUAL_TRAINING = '/training/virtual',
  ONSITE_TRAINING = '/training/onsite',
  VEHICLE_INFO = '/vehicle',
  ERROR = '/error',
  RESUME = '/resume',
}

export const PATH_WITH_FORM = [
  Path.BASIC_INFO,
  Path.PERSONAL_INFO,
  Path.ADDITIONAL_INFO,
  Path.VEHICLE_INFO,
]

type PathConfig = {
  path: Path
  configs: {
    leftAction?: DriverRegNavLeftButtonType
    rightAction: DriverRegNavRightButtonType
  }
}
export const PATH_CONFIGS: PathConfig[] = [
  {
    path: Path.LANDING,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
      leftAction: DriverRegNavLeftButtonType.CLOSE,
    },
  },
  {
    path: Path.RESUME,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
      leftAction: DriverRegNavLeftButtonType.BACK,
    },
  },
  {
    path: Path.OTP_VERIFICATION,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
      leftAction: DriverRegNavLeftButtonType.BACK,
    },
  },
  {
    path: Path.BASIC_INFO,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
      leftAction: DriverRegNavLeftButtonType.BACK,
    },
  },
  {
    path: Path.PERSONAL_INFO,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
  {
    path: Path.VEHICLE_INFO,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
  {
    path: Path.TRAINING_SELECTION,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
  {
    path: Path.VIRTUAL_TRAINING,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
      leftAction: DriverRegNavLeftButtonType.BACK,
    },
  },
  {
    path: Path.ONSITE_TRAINING,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
      leftAction: DriverRegNavLeftButtonType.BACK,
    },
  },
  {
    path: Path.ADDITIONAL_INFO,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
  {
    path: Path.COMPLETED_STEPS,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
  {
    path: Path.RESUBMIT,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
      leftAction: DriverRegNavLeftButtonType.BACK,
    },
  },
  {
    path: Path.VERIFIED,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
  {
    path: Path.REJECTED,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
  {
    path: Path.ERROR,
    configs: {
      rightAction: DriverRegNavRightButtonType.HELP,
    },
  },
]
