import { RemoteConfigKey } from '@/v1/constants/remoteConfigKey'
import { TrackingData } from '@/v1/types/firebaseAnalytics'
import { firebaseAnalyticsApp, getMmpDeviceType } from '@/v1/utils'
import { getAnalytics, logEvent } from '@firebase/analytics'
import { useRemoteConfig } from '@v1/hooks'
import { MMPEvents, Registration } from '@v1/types'
import { shallowRemoveEmptyProps } from '@v1/utils'
import { useCallback } from 'react'

import { useTempFormValue, useVehicleTypes } from './'
import { useRegion } from './useRegion'
import { useRegistrationConfig } from './useRegistrationConfig'

export const useFirebaseAnalytics = (registrationInfo?: Registration) => {
  const { should } = useRemoteConfig()
  const isFirebaseAnalyticsEnabled = should(
    RemoteConfigKey.USE_FIREBASE_ANALYTICS
  )

  const firebaseAnalytics = getAnalytics(firebaseAnalyticsApp)
  const { registrationConfig } = useRegistrationConfig()
  const { marketId, cityId } = useRegion()
  const { tempFormValues } = useTempFormValue()

  const getCityName = useCallback(
    (cityIdParam: number | string) => {
      const cityObject = (registrationConfig?.city_list || []).find(
        city => city?.id.toString() === cityIdParam.toString()
      )

      return cityObject?.name_en || cityObject?.name
    },
    [registrationConfig]
  )

  const { data: vehicleTypes = [] } = useVehicleTypes(
    Number(tempFormValues.cityId) || cityId
  )

  const getVehicleTypeName = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.label,
    [vehicleTypes]
  )

  const getVehicleTypeWheelType = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.wheel_type || '',
    [vehicleTypes]
  )

  const logFirebaseEvent = useCallback(
    async (event: string, metaData?: TrackingData) => {
      if (isFirebaseAnalyticsEnabled) {
        const eventData = shallowRemoveEmptyProps({
          registration_id: registrationInfo?.registration_id,
          country_id: marketId.toString(),
          device_type: getMmpDeviceType(),
          city_id:
            metaData?.city_id?.toString() ||
            registrationInfo?.city_id.toString(),
          vehicle_type_id:
            metaData?.vehicle_type_id?.toString() ||
            registrationInfo?.vehicle_type_id.toString(),
          city: getCityName(
            metaData?.city_id || registrationInfo?.city_id || 0
          ),
          vehicle_type_name: getVehicleTypeName(
            metaData?.vehicle_type_id || registrationInfo?.vehicle_type_id || 0
          ),
          language: registrationInfo?.language,
          ...(metaData || {}),
        })

        // support adding wheel_type to these certain events, see PRD https://huolala.feishu.cn/wiki/XgPJwB4aBiB6AukdUEFcWuyNnce
        const isWheelTypeEvent =
          event === MMPEvents.REGISTER_COMPLETED ||
          event === MMPEvents.REGISTER_LEAD_VERIFIED

        if (isWheelTypeEvent) {
          // call tracking with wheel_type
          logEvent(
            firebaseAnalytics,
            `${event}_${getVehicleTypeWheelType(
              registrationInfo?.vehicle_type_id || 0
            )}`,
            eventData
          )
        }

        logEvent(
          firebaseAnalytics,
          isWheelTypeEvent ? `${event}` : event,
          eventData
        )
      } else {
        return
      }
    },
    [
      getCityName,
      registrationInfo?.city_id,
      registrationInfo?.registration_id,
      registrationInfo?.vehicle_type_id,
      registrationInfo?.language,
      getVehicleTypeName,
      isFirebaseAnalyticsEnabled,
      firebaseAnalytics,
      marketId,
      getVehicleTypeWheelType,
    ]
  )

  return {
    logFirebaseEvent,
  }
}
