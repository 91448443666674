import { DriverChoreSDK, DriverRegSDK } from '@hll/driver-webview-bridge-js'
import { isProduction } from '@v1/constants'
import { TrackingData } from '@v1/types'
import { isMobile } from 'react-device-detect'

export const driverRegSdkInstance = new DriverRegSDK({
  showDebugInfo: !isProduction,
  // This project doesn't catch error for web call
  // Throw error may crash the site
  suppressError: true,
})

export const driverChoreSdk = new DriverChoreSDK({
  showDebugInfo: true,
  // This project doesn't catch error for web call
  // Throw error may crash the site
  suppressError: true,
})

// TODO: remaining integrations
/**
  // react-pin-input no way to set value programmatically, on hold
  startMonitorSms
 */

export const logBranchEventTracking = (trackingData: TrackingData) => {
  driverRegSdkInstance.driverAppWebCall({
    action: 'branchTracking', // TODO: webview sdk integrate
    data: trackingData,
  })
}

// TODO: get whitelisted device
if (isMobile) {
  driverRegSdkInstance.injectDevTools({
    useCheatCode: true,
    isAutoInject: !isProduction,
  })
}
