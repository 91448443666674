import { RegistrationStatus, TrainingType } from '@v1/constants'
import { Path } from '@v1/constants/path'
import { FormConfigSkips, Registration } from '@v1/types'
import { isNil } from 'ramda'

import { handleError } from './errorHandler'

// TODO: test
export const getRedirectRoute = (param: {
  registrationInfo: Registration
  formConfigSkips: FormConfigSkips
  currentRegStatus: RegistrationStatus
  isVirtualTrainingAvailable: boolean
  isOnSiteTrainingAvailable: boolean
  isFleet?: boolean
}): Path => {
  const {
    formConfigSkips,
    registrationInfo: {
      is_additional_info_submitted: isAdditionalInfoSubmitted,
      training_type: trainingType,
    },
    currentRegStatus,
    isVirtualTrainingAvailable,
    isOnSiteTrainingAvailable,
    isFleet,
  } = param

  const {
    isPersonalInfoSkipped,
    isVehicleInfoSkipped,
    isAdditionalInfoSkipped,
    isTrainingSkipped,
  } = formConfigSkips

  switch (currentRegStatus) {
    case RegistrationStatus.CREATED:
      return Path.BASIC_INFO
    case RegistrationStatus.BASIC_INFO_SUBMITTED:
      if (isNil(isPersonalInfoSkipped)) {
        handleError(
          `form config not match registration status: ${currentRegStatus}`
        )

        return Path.ERROR
      }

      if (isPersonalInfoSkipped) {
        return getRedirectRoute({
          ...param,
          currentRegStatus: RegistrationStatus.PERSONAL_INFO_SUBMITTED,
        })
      }

      return Path.PERSONAL_INFO
    case RegistrationStatus.PERSONAL_INFO_SUBMITTED:
      if (isNil(isVehicleInfoSkipped)) {
        handleError(
          `form config not match registration status: ${currentRegStatus}`
        )

        return Path.ERROR
      }

      if (isVehicleInfoSkipped) {
        return getRedirectRoute({
          ...param,
          currentRegStatus: RegistrationStatus.VEHICLE_INFO_SUBMITTED,
        })
      }

      return Path.VEHICLE_INFO
    case RegistrationStatus.VEHICLE_INFO_SUBMITTED:
    case RegistrationStatus.VIRTUAL_TRAINING_STARTED:
      if (isTrainingSkipped) {
        return getRedirectRoute({
          ...param,
          currentRegStatus: RegistrationStatus.ONSITE_TRAINING_SCHEDULED,
        })
      }

      if (!isVirtualTrainingAvailable && !isOnSiteTrainingAvailable) {
        handleError('trainings unavailable')
        return Path.ERROR
      }

      if (isFleet) {
        return Path.TRAINING_SELECTION
      }

      if (isVirtualTrainingAvailable && !isOnSiteTrainingAvailable) {
        return Path.VIRTUAL_TRAINING
      }

      if (!isVirtualTrainingAvailable && isOnSiteTrainingAvailable) {
        return Path.ONSITE_TRAINING
      }

      return Path.TRAINING_SELECTION
    case RegistrationStatus.TRAINING_ABSENT:
    case RegistrationStatus.TRAINING_CANCELED:
      if (isAdditionalInfoSkipped || isAdditionalInfoSubmitted) {
        return Path.COMPLETED_STEPS
      }

      return getRedirectRoute({
        ...param,
        currentRegStatus: RegistrationStatus.VEHICLE_INFO_SUBMITTED,
      })
    case RegistrationStatus.ONSITE_TRAINING_SCHEDULED:
    case RegistrationStatus.TRAINING_PASSED:
      if (isAdditionalInfoSubmitted || isAdditionalInfoSkipped) {
        return Path.COMPLETED_STEPS
      }

      if (isNil(isAdditionalInfoSkipped)) {
        handleError(
          `form config not match registration status: ${currentRegStatus}`
        )

        return Path.ERROR
      }

      return Path.ADDITIONAL_INFO
    case RegistrationStatus.TRAINING_FAILED:
      if (trainingType === TrainingType.VIRTUAL) {
        if (!isVirtualTrainingAvailable && !isOnSiteTrainingAvailable) {
          return Path.REJECTED
        }

        if (isFleet) {
          return Path.TRAINING_SELECTION
        }

        if (isVirtualTrainingAvailable && !isOnSiteTrainingAvailable) {
          return Path.VIRTUAL_TRAINING
        }

        if (!isVirtualTrainingAvailable && isOnSiteTrainingAvailable) {
          return Path.ONSITE_TRAINING
        }

        return getRedirectRoute({
          ...param,
          currentRegStatus: RegistrationStatus.VEHICLE_INFO_SUBMITTED,
        })
      }

      if (isFleet) {
        handleError('unexpected error')
        return Path.ERROR
      }

      if (trainingType === TrainingType.ONSITE) {
        if (isAdditionalInfoSubmitted) {
          return Path.COMPLETED_STEPS
        }

        return getRedirectRoute({
          ...param,
          currentRegStatus: RegistrationStatus.VEHICLE_INFO_SUBMITTED,
        })
      }

      handleError('unexpected error')
      return Path.ERROR
    case RegistrationStatus.APPROVED:
      return Path.VERIFIED
    case RegistrationStatus.REJECTED: {
      return Path.REJECTED
    }

    default:
      handleError(
        `cannot resolve status ${typeof currentRegStatus} ${currentRegStatus}`
      )

      return Path.ERROR
  }
}
