export enum RemoteConfigKey {
  USE_PERSONAL_DRIVER_FLOW = 'use_personal_driver_flow',
  USE_OCR = 'use_ocr',
  USE_LUNA_UPLOAD = 'use_luna_upload',
  USE_V2_STRUCTURE = 'use_v2_structure',
  USE_PH_PROJECT_IO_DOCUMENTS_REMOVAL = 'use_project_io_documents_removal',
  USE_PH_FOUR_WHEEL_DOCUMENTS_REMOVAL = 'use_ph_four_wheel_documents_removal',
  USE_PH_VEHICLE_TYPE_ID_REMOVAL = 'use_vehicle_type_id_removal',
  USE_APPLY_SPECIAL_FILTER_FOR_PH = 'use_apply_special_filter_for_ph',
  USE_FIREBASE_ANALYTICS = 'use_firebase_analytics',
  USE_APPSFLYER_EVENT_TRACKING = 'use_appsflyer_event_tracking',
  USE_APPSFLYER_BANNER = 'use_appsflyer_banner',
  USE_VEHICLE_OWNERSHIP_FIELD = 'use_vehicle_ownership_field',
  USE_VEHICLE_PICKER_MODAL = 'use_vehicle_picker_modal',
  USE_IFRAME_AF_WEB_S2S = 'use_iframe_af_web_s2s',
  USE_WORK_IN_OTHER_PLATFORM_FIELD = 'use_work_in_other_platform_field',
  USE_VIRTUAL_TRAINING_FALLBACK_FLOW = 'use_virtual_training_fallback_flow',
  USE_V2_PHOTO_PICKER = 'use_v2_photo_picker',
  USE_PHOTO_META = 'use_photo_meta',
  USE_PENDING_DOCUMENTS = 'use_pending_documents',
  USE_ODOKO_OTP = 'use_odoko_otp',
  USE_DRD_REFERRAL_CODE_FIELD = 'use_drd_referral_code_field',
}
