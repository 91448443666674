import { VehicleType } from '@v1/types/vehicleType'

import { apiClient } from './apiClient'

const get = async ({
  cityId,
  deviceId,
}: {
  cityId: string | number
  deviceId?: string
}) => {
  const { data } = await apiClient.get<VehicleType[]>('/vehicle-types', {
    params: { city_id: cityId, ...(deviceId ? { device_id: deviceId } : {}) },
  })

  return data
}

export const vehicleTypeApi = { get }
