import { ApiErrors } from '@v1/constants/errors'

import { DriverCRMWebAPIError } from './driverCRMWebAPIError'

export type ValidationErrorDetail = {
  message: string
  parameter: string
}
export class DriverCRMWebAPIInvalidParamError extends DriverCRMWebAPIError {
  override code: ApiErrors.INVALID_PARAMETER
  override details: ValidationErrorDetail[]
  override message: string
  constructor({
    message,
    code = ApiErrors.INVALID_PARAMETER,
    details,
    ...rest
  }: {
    code?: ApiErrors.INVALID_PARAMETER
    message: string
    details: ValidationErrorDetail[]
  }) {
    super({
      message,
      code,
      details,
      ...rest,
    })

    // eslint-disable-next-line fp/no-mutation
    this.message = message
    // eslint-disable-next-line fp/no-mutation
    this.code = code
    // eslint-disable-next-line fp/no-mutation
    this.details = details
  }
}

export const isDriverCRMWebAPIInvalidParamError = (
  e: any
): e is DriverCRMWebAPIInvalidParamError =>
  e instanceof DriverCRMWebAPIInvalidParamError
