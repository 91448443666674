import { useQuery } from '@tanstack/react-query'
import { Path, PathError } from '@v1/constants'
import { ApiErrors } from '@v1/constants/errors'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { fleetApi } from '@v1/services/dcrm'
import { DriverCRMWebAPIError } from '@v1/services/dcrm/errors'
import { handleError } from '@v1/utils'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

import { useQueryParam, useSetLoading } from '.'

export const useFleet = () => {
  const query = useQueryParam()
  const setLoading = useSetLoading()
  const fleetInvitationCode = query.get(QueryStringKey.FLEET_INV_CODE)
  const isFleet = !isNil(fleetInvitationCode)
  const routeHistory = useHistory()
  const {
    data: fleetInfo,
    isLoading,
    isError,
    ...rest
  } = useQuery(
    ['fleet-info', fleetInvitationCode],
    () => {
      if (!fleetInvitationCode) return
      return fleetApi.getInfoByInvitationCode({
        invitationCode: fleetInvitationCode,
      })
    },
    {
      enabled: isFleet,
      onError: e => {
        if (!(e instanceof DriverCRMWebAPIError)) {
          handleError(e)
          routeHistory.replace(Path.ERROR)
          return
        }

        switch (e.code) {
          case ApiErrors.RESOURCE_NOT_FOUND:
            routeHistory.replace(Path.ERROR, {
              errorType: PathError.FLEET_LINK_EXPIRED,
            })

            break
          default:
            handleError(e)
            routeHistory.replace(Path.ERROR)
            break
        }
      },
    }
  )

  useEffect(() => {
    setLoading({
      isFleetLoading: isFleet && isLoading,
    })
  }, [isLoading, isFleet, setLoading])

  return {
    fleetInfo,
    isFleet,
    isLoading: isFleet && isLoading,
    isError,
    ...rest,
  }
}
