/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable fp/no-mutation */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-unused-expressions */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
const webKey = String(import.meta.env.VITE_APP_APPSFLYER_WEB_APP_ID || '')
const bannerKey = String(import.meta.env.VITE_APP_APPSFLYER_BANNER_KEY || '')

// appsflyer sdk script https://support.appsflyer.com/hc/en-us/articles/360001610038-PBA-Web-SDK-integration-guide
try {
  !(function (t, e, n, s, a, c, i, o, p) {
    ;(t.AppsFlyerSdkObject = a),
      (t.AF =
        t.AF ||
        function () {
          ;(t.AF.q = t.AF.q || []).push(
            [Date.now()].concat(Array.prototype.slice.call(arguments))
          )
        }),
      (t.AF.id = t.AF.id || i),
      (t.AF.plugins = {}),
      (o = e.createElement(n)),
      (p = e.getElementsByTagName(n)[0]),
      (o.async = 1),
      (o.src = `https://websdk.appsflyer.com?${
        c.length > 0 ? `st=${c.split(',').sort().join(',')}&` : ''
      }${i.length > 0 ? `af_id=${i}` : ''}`),
      p.parentNode.insertBefore(o, p)
  })(window, document, 'script', 0, 'AF', 'pba,banners', {
    pba: {
      webAppId: webKey,
    },
    banners: {
      key: bannerKey,
    },
  })
} catch (e) {
  console.error('AF:sdk error', e)
}
