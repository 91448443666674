import { initializeApp } from 'firebase/app'
import { envConfig } from '../constants'

export const firebaseAnalyticsApp = initializeApp(
  {
    apiKey: String(envConfig.VITE_APP_FIREBASE_ANALYTICS_API_KEY || ''),
    authDomain: String(envConfig.VITE_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN || ''),
    projectId: String(envConfig.VITE_APP_FIREBASE_ANALYTICS_PROJECT_ID || ''),
    storageBucket: String(
      envConfig.VITE_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET || ''
    ),
    messagingSenderId: String(
      envConfig.VITE_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID || ''
    ),
    appId: String(envConfig.VITE_APP_FIREBASE_ANALYTICS_APP_ID || ''),
    measurementId: String(
      envConfig.VITE_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID || ''
    ),
  },
  'ANALYTICS'
)
