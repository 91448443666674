import { AxiosResponse } from 'axios'

import { apiClient } from './apiClient'

const sendEvent = ({
  registrationId,
  eventName,
  eventValue,
  afUserId,
}: {
  registrationId: string
  eventName: string
  eventValue: Record<string, unknown>
  afUserId?: string
}) =>
  apiClient.post<
    AxiosResponse<{
      error?: string
      message?: string
      status: number
    }>
  >('/appsflyer/track-event', {
    customer_user_id: registrationId,
    af_user_id: afUserId,
    event_name: eventName,
    event_value: JSON.stringify(eventValue),
  })

export const appsflyerApi = {
  sendEvent,
}
