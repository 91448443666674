import { ViteAppIDC } from '../v1/constants/dataCentre'
import sgLocal from '../../configs/sg-local'
import sgPre from '../../configs/sg-pre'
import sgPreUncled from '../../configs/sg-pre-uncled'
import sgPrd from '../../configs/sg-prd'
import sgStg from '../../configs/sg-stg'
import sgStgUncled from '../../configs/sg-stg-uncled'
import inPrd from '../../configs/in-prd'
import brPrd from '../../configs/br-prd'

export const getBuildTimeConfig = async (mode: ViteAppIDC | string) => {
  const finalMode = mode || import.meta.env.MODE

  if (finalMode === 'test') {
    return sgLocal
  }

  if (finalMode === ViteAppIDC.BR_PROD) {
    return brPrd
  } else if (finalMode === ViteAppIDC.IN_PROD) {
    return inPrd
  } else if (finalMode === ViteAppIDC.SG_PROD) {
    return sgPrd
  } else if (finalMode === ViteAppIDC.SG_PRE) {
    return sgPre
  } else if (finalMode === ViteAppIDC.SG_PRE_UNCLED) {
    return sgPreUncled
  } else if (finalMode === ViteAppIDC.SG_STG) {
    return sgStg
  } else if (finalMode === ViteAppIDC.SG_STG_UNCLED) {
    return sgStgUncled
  }

  if (finalMode === 'van') {
    if (process.env.VITE_APP_IDC === ViteAppIDC.BR_PROD) {
      return brPrd
    }
    if (process.env.VITE_APP_IDC === ViteAppIDC.IN_PROD) {
      return inPrd
    }
    if (process.env.VITE_APP_IDC === ViteAppIDC.SG_PROD) {
      return sgPrd
    }
  }
  return sgLocal
}

export const getRuntimeConfig = async () => {
  const vanEnv = document.documentElement.dataset.vanEnv
  const vanIdc = document.documentElement.dataset.vanIdc
  if (vanEnv) {
    // running in van env remote
    if (vanEnv === 'stg' && vanIdc === 'bom') {
      return sgStgUncled
    }
    if (vanEnv === 'stg' && vanIdc === 'sin') {
      return sgStg
    }
    if (vanEnv === 'pre' && vanIdc === 'bom') {
      return sgPreUncled
    }
    if (vanEnv === 'pre' && vanIdc === 'sin') {
      return sgPre
    }

    if (vanEnv === 'prod') {
      if (vanIdc === 'sin') {
        return sgPrd
      } else if (vanIdc === 'bom') {
        return inPrd
      } else if (vanIdc === 'sao') {
        return brPrd
      }
    }

    throw new Error('getRuntimeConfig:config not found')
  }
  
  // local dev
  return sgLocal
}