import { useLocalStorage } from 'react-use'
import { safeParse } from 'secure-json-parse'
/**
 * Wrapper around useLocalStorage to sync value in real time,
 * cater case where two rendered component accessing same local storage entry
 * @param key
 * @param initialValue
 * @param options
 * @returns
 */
export const useLocalStorageRT = <T>(
  key: string,
  initialValue?: T,
  options?:
    | {
        raw: true
      }
    | {
        raw: false
        serializer: (value: T) => string
        deserializer: (value: string) => T
      }
    | undefined
): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>,
  () => void
] => {
  const [, setValue, remove] = useLocalStorage<T>(key, initialValue, options)

  const valueRealTime: T | undefined = safeParse(
    localStorage?.getItem(key) || ''
  )

  return [valueRealTime, setValue, remove]
}
