import { captureException } from '@sentry/react'
import { isDev } from '@v1/constants'

export const handleError = (e: unknown) => {
  const error = e instanceof Error ? e : typeof e === 'string' ? Error(e) : e
  if (isDev) {
    console.error(error)
  } else {
    captureException(error)
  }
}
