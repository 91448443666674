import { QueryStringKey } from '@/v1/constants/queryStringKey'
import { useLocation } from 'react-router'

export class RegURLSearchParams extends URLSearchParams {
  override get(name: QueryStringKey) {
    return super.get(name)
  }
}

export const useQueryParam = () => new RegURLSearchParams(useLocation().search)
export const getQueryParams = () => new RegURLSearchParams(location.search)
