import { useQuery } from '@tanstack/react-query'
import { LocalStorageKey, Path, isBrazil, isIndia } from '@v1/constants'
import { COUNTRY_PHONE_CONFIG } from '@v1/constants/phoneNumber'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import constate from 'constate'
import qs from 'qs'
import { includes } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useLocalStorage } from 'react-use'

import { regionConfigApi } from './../services/regionConfig/regionConfig'
import { CurrencyConfig } from '../types/regionConfig'
import { useQueryParam } from './useQueryParam'
import { useSetLoading } from '.'

export const fallbackMarketId = isIndia ? 10000 : !isBrazil ? 90000 : 20000

export const [UseRegionProvider, useRegion] = constate(() => {
  const query = useQueryParam()
  const deviceId = query.get(QueryStringKey.DEVICE_ID) || undefined
  const queryMarketId =
    Number(query.get(QueryStringKey.HCOUNTRY)) ||
    Number(query.get(QueryStringKey.MARKET_ID))

  const [currentMarketId = fallbackMarketId, setMarketId] = useLocalStorage(
    LocalStorageKey.MARKET_ID,
    fallbackMarketId
  )

  const [cityId, setCityId] = useState(0)

  const { data: regionConfig, isLoading: isLoadingRegionConfig } = useQuery(
    ['region-config', currentMarketId],
    () =>
      regionConfigApi.get({
        deviceId,
      })
  )

  const setLoading = useSetLoading()

  const history = useHistory()
  const location = useLocation<{ from: Path }>()

  useEffect(() => {
    if (isIndia) {
      setMarketId(10000)
    } else if (queryMarketId) {
      setMarketId(queryMarketId)
    }
  }, [queryMarketId, setMarketId])

  const currentRegionConfig = useMemo(
    () => regionConfig?.find(({ countryId }) => countryId === currentMarketId),
    [regionConfig, currentMarketId]
  )

  useEffect(() => {
    setLoading({
      isLoadingRegionConfig,
    })
  }, [isLoadingRegionConfig, setLoading])

  useEffect(() => {
    if (currentRegionConfig) {
      const hlangQuery = query.get(QueryStringKey.HLANG)
      const regionHlangs = currentRegionConfig.languages.map(lang =>
        lang.id.toLowerCase()
      )

      if (!includes(hlangQuery?.toLowerCase(), regionHlangs)) {
        const currentQuery = qs.parse(location.search, {
          ignoreQueryPrefix: true,
        })

        const newParams = qs.stringify({
          ...currentQuery,
          hlang: currentRegionConfig?.defaultLanguage.toLowerCase(),
        })

        history.push({
          pathname: location.pathname,
          search: newParams,
        })
      }
    }
  }, [query, history, location, currentRegionConfig])

  return {
    cityId,
    hasCityId: cityId > 0,
    marketId: currentMarketId,
    setMarketId,
    setCityId,
    languages: currentRegionConfig?.languages || [],
    dialCode: COUNTRY_PHONE_CONFIG[currentMarketId]?.dialCode,
    phoneMaxLength: COUNTRY_PHONE_CONFIG[currentMarketId]?.phoneMaxLength,
    isLoadingRegionConfig,
    currency: currentRegionConfig?.currency as CurrencyConfig,
  }
})
