import { ViteAppIDC } from '../src/v1/constants/dataCentre'

export default {
  NODE_ENV: 'production',
  VITE_APP_IDC: ViteAppIDC.IN_PROD,
  VITE_APP_THEME: 'uncleD',
  VITE_APP_DCRM_API_BASE_URL: 'https://dcrm-api.bom.uncle-delivery.com/web-api',
  VITE_APP_LAFORME_API_BASE_URL:
    'https://laforme-api.bom.uncle-delivery.com/api/v1/published-schemas/driver-registration-form',
  VITE_APP_REGION_CONFIG_API_URL:
    'https://region-configuration.uncle-delivery.com',
  VITE_APP_DRIVER_REG_FILE_API_URL: 'https://uimg.bom.uncle-delivery.com',
  VITE_APP_ODOKO_API_URL: 'https://odoko-api.bom.uncle-delivery.com',
  VITE_APP_ODOKO_CLIENT_ID: '3UaZJS-F9l-lKj3UwSTAD',
  VITE_APP_APPSFLYER_URL: 'uncle-delivery.com',

  VITE_APP_FIREBASE_API_KEY: 'AIzaSyAfwBgA9acH8RauSLEJ9H0tg4DDLdXcbLY',
  VITE_APP_FIREBASE_AUTH_DOMAIN: 'global-driver-registration.firebaseapp.com',
  VITE_APP_FIREBASE_PROJECT_ID: 'global-driver-registration',
  VITE_APP_FIREBASE_STORAGE_BUCKET: 'global-driver-registration.appspot.com',
  VITE_APP_FIREBASE_MESSAGING_SENDER_ID: '414089515562',
  VITE_APP_FIREBASE_APP_ID: '1:414089515562:web:8ad7318e2cfb46c75c2953',

  VITE_APP_LUNA_UPLOAD_API_BASE_URL:
    'https://luna.bom.uncle-delivery.com/luna/api',
  VITE_APP_LUNA_PUBLIC_BASE_URL:
    'https://luna-india-public.s3.ap-south-1.amazonaws.com',

  VITE_APP_FIREBASE_ANALYTICS_API_KEY:
    'AIzaSyBzJurJSpMlR7joPuqFmuSxZ3npWOSlwpM',
  VITE_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN: 'uncle-delivery.firebaseapp.com',
  VITE_APP_FIREBASE_ANALYTICS_PROJECT_ID: 'uncle-delivery',
  VITE_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET: 'uncle-delivery.appspot.com',
  VITE_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID: '149857292873',
  VITE_APP_FIREBASE_ANALYTICS_APP_ID:
    '1:149857292873:web:70ceae24db29c8c2332598',
  VITE_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID: 'G-0783QK9KFE',

  VITE_SENTRY_ORG: 'uncle-delivery',
  VITE_SENTRY_PROJECT: 'global-driver-registration',
  VITE_APP_SENTRY_DSN:
    'https://7629bfa97f3a2e40fc5a2b4e1afd1a27@o4505118865555456.ingest.sentry.io/4505757461970944',

  VITE_APP_SENSORS_SERVER_URL:
    'https://uba.prd.bom.uncle-delivery.com/sa?project=production',

  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_COUNT: '20',
  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_INTERVAL: '1',

  VITE_APP_GTM_ID: 'GTM-MC7ZZ5K',
  VITE_APP_BRANCH_KEY: 'key_live_ed0ECUjkXZtsugqygBiVSlljyuh8yYnE',
  VITE_APP_HCAPTCHA_SITE_KEY: '65dabe32-dc89-4549-9c5f-02d03f40434a',

  VITE_APP_DOWNLOAD_DRIVER_APP_URL: 'https://www.uncle-delivery.com',

  VITE_APP_APPSFLYER_WEB_APP_ID: 'a4dfa785-0837-4173-bf0e-b71be553efda',
  VITE_APP_APPSFLYER_BANNER_KEY: '4069a577-2daf-41b3-b457-ea8a745bfc5d',
}
