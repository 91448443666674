import { Lalamove, UncleD } from '@hll/driver-icons/react'
import { DefaultPalette } from '@hll/driver-webview-components'
import { ZIndex, isIndia } from '@v1/constants'
import React from 'react'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'

type Props = {
  isLoading: boolean
  fullscreen?: boolean
}

const LoaderIcon = isIndia ? UncleD : Lalamove
export const PurePageLoading: React.FC<Props> = ({ isLoading, fullscreen }) => (
  <Transition in={isLoading} timeout={300}>
    {state => (
      <Wrapper visible={['entering', 'entered'].includes(state)}>
        {fullscreen && isLoading && <LoadingOverlay />}
        <LoadingContainer shouldPlayAnimation={isLoading}>
          <StyledBrandIcon />
        </LoadingContainer>
      </Wrapper>
    )}
  </Transition>
)

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: ${ZIndex.LOADING};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}
`

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
`

const LoadingContainer = styled.div<{ shouldPlayAnimation: boolean }>`
  position: relative;
  background: ${DefaultPalette.MINE_SHAFT_800};
  border-radius: 10px;
  padding: 20px;

  &::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    transform: translate(10%, 10%);

    animation: l-loading-circle 0.5s linear
      ${({ shouldPlayAnimation }) =>
        shouldPlayAnimation ? 'infinite' : 'forwards'};
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    border-left-color: #fff;
    box-sizing: border-box;
  }

  @-webkit-keyframes l-loading-circle {
    0% {
      transform-origin: center center;
      transform: rotate(0deg);
    }
    100% {
      transform-origin: center center;
      transform: rotate(360deg);
    }
  }

  @keyframes l-loading-circle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const StyledBrandIcon = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
  color: #fff;
  display: block;
`
