import { ViteAppIDC } from '../src/v1/constants/dataCentre'

export default {
  NODE_ENV: 'development',
  VITE_APP_THEME: 'lalamove',
  VITE_APP_IDC: ViteAppIDC.SG_STG,
  VITE_APP_DCRM_API_BASE_URL: '/web-api',
  VITE_APP_LAFORME_API_BASE_URL:
    'https://sg-laforme-api-stg.lalamove.com/api/v1/published-schemas/driver-registration-form',
  VITE_APP_REGION_CONFIG_API_URL:
    'https://region-configuration-stg.lalamove.com',
  VITE_APP_DRIVER_REG_FILE_API_URL: 'https://sg-uimg-stg.lalamove.com',
  VITE_APP_ODOKO_API_URL: 'https://sg-odoko-api-stg.lalamove.com',
  VITE_APP_ODOKO_CLIENT_ID: 'pViuerL_N_pZ1uXV6dIdN',
  VITE_APP_APPSFLYER_URL: 'lalamove.com',

  VITE_APP_FIREBASE_API_KEY: 'AIzaSyAfwBgA9acH8RauSLEJ9H0tg4DDLdXcbLY',
  VITE_APP_FIREBASE_AUTH_DOMAIN: 'global-driver-registration.firebaseapp.com',
  VITE_APP_FIREBASE_PROJECT_ID: 'global-driver-registration',
  VITE_APP_FIREBASE_STORAGE_BUCKET: 'global-driver-registration.appspot.com',
  VITE_APP_FIREBASE_MESSAGING_SENDER_ID: '414089515562',
  VITE_APP_FIREBASE_APP_ID: '1:414089515562:web:8ad7318e2cfb46c75c2953',

  VITE_APP_LUNA_UPLOAD_API_BASE_URL:
    'https://sg-luna-stg.lalamove.com/luna/api',
  VITE_APP_LUNA_PUBLIC_BASE_URL:
    'https://luna-public.s3-ap-southeast-1.amazonaws.com',

  VITE_APP_FIREBASE_ANALYTICS_API_KEY:
    'AIzaSyDpbYMWceFXA2HnJZpnK2Rsplh7EP22iMs',
  VITE_APP_FIREBASE_ANALYTICS_AUTH_DOMAIN: 'easyvandev.firebaseapp.com',
  VITE_APP_FIREBASE_ANALYTICS_PROJECT_ID: 'easyvandev',
  VITE_APP_FIREBASE_ANALYTICS_STORAGE_BUCKET: 'easyvandev.appspot.com',
  VITE_APP_FIREBASE_ANALYTICS_MESSAGING_SENDER_ID: '313417191062',
  VITE_APP_FIREBASE_ANALYTICS_APP_ID:
    '1:313417191062:web:55a279441d6fcada8efcb7',
  VITE_APP_FIREBASE_ANALYTICS_MEASUREMENT_ID: 'G-7MYKYS5HLV',

  VITE_SENTRY_ORG: 'lalamove-tech',
  VITE_SENTRY_PROJECT: 'global-driver-registration',
  VITE_APP_SENTRY_DSN:
    'https://3b4c9c4e3f28402ab28e996910238c0a@o562705.ingest.sentry.io/6189713',

  VITE_APP_SENSORS_SERVER_URL:
    'https://uba.huolalamove.net/sa?project=production',

  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_COUNT: '20',
  VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_INTERVAL: '1',

  VITE_APP_GTM_ID: 'GTM-N2JSKRT',
  VITE_APP_BRANCH_KEY: 'key_test_nn4k14CSjURjtojqsEghlhjguqmWIc2j',
  VITE_APP_HCAPTCHA_SITE_KEY: '11d502db-124d-4d86-98fd-a427592d2fc0',

  VITE_APP_DOWNLOAD_DRIVER_APP_URL: 'https://www.lalamove.com/driver-app/sea',

  VITE_APP_APPSFLYER_WEB_APP_ID: 'a33d6678-a2d2-473b-8e20-900785dd63fc',
  VITE_APP_APPSFLYER_BANNER_KEY: 'b1ffbaf5-760a-4f32-a51b-60fed327fce4',
}
