import { QueryStringKey } from '@v1/constants/queryStringKey'
import { isMobile } from 'react-device-detect'

import { getQueryParams } from '../hooks'

export const getMmpDeviceType = () => {
  const queryClientType = getQueryParams().get(QueryStringKey.CLIENT_TYPE)
  switch (queryClientType) {
    case 'android_app':
      return 'android'
    case 'ios_app':
      return 'ios'
    case 'web':
      return isMobile ? 'mobile_web' : 'desktop_web'
    default:
      return 'web'
  }
}
