import { isInDriverAppWebView } from '@hll/driver-webview-bridge-js'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import errorSvg from '@themedImages/error.svg'
import {
  CacheKey,
  LocalStorageKey,
  Path,
  PathError,
  RemoteConfigKey,
  SensorsEvent,
} from '@v1/constants'
import { ApiErrors } from '@v1/constants/errors'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { useRemoteConfig, useSensorTracker } from '@v1/hooks'
import { useFirebaseAnalytics } from '@v1/hooks/useFirebaseAnalytics'
import { appsflyerApi, registrationApi } from '@v1/services/dcrm'
import { DriverCRMWebAPIError } from '@v1/services/dcrm/errors'
import { MMPEvents, PhoneNumber, Registration } from '@v1/types'
import { getMmpDeviceType, handleError } from '@v1/utils'
import { isNotNil, without } from 'ramda'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { safeParse } from 'secure-json-parse'

import { useBranch } from './useBranch'
import { useLocalStorageRT } from './useLocalStorageRealTime'
import {
  useAppsflyer,
  useAuth,
  useModal,
  usePrevPath,
  useQueryParam,
  useRegion,
  useRegistrationConfig,
  useRegistrationInfo,
  useSetLoading,
  useTempFormValue,
  useVehicleTypes,
} from '.'

type MutationCallbacks = {
  onError?: (e: unknown) => void
  onSuccess?: (d?: Registration) => void
}

export const useRegistrationMutation = (options?: MutationCallbacks) => {
  const { should } = useRemoteConfig()
  const { registrationInfo } = useRegistrationInfo()
  const routerHistory = useHistory()
  const queryClient = useQueryClient()
  const { tempFormValues, setTempFormValues } = useTempFormValue()
  const { t } = useTranslation()
  const { registrationConfig } = useRegistrationConfig()
  const { logout } = useAuth()
  const { open } = useModal()
  const setLoading = useSetLoading()
  const query = useQueryParam()
  const { cityId } = useRegion()
  const prevPath = usePrevPath()
  const [rejectionAckedPhones, setAcknowledgements] = useLocalStorageRT<
    PhoneNumber[]
  >(LocalStorageKey.REJECTION_ACKNOWLEDGED_BY_PHONE)

  const fleetInvitationCode = query.get(QueryStringKey.FLEET_INV_CODE)
  const stoneTemplateCode = query.get(QueryStringKey.STONE_TEMPLATE_CODE)
  const driverReferCode = query.get(QueryStringKey.DRIVER_REFER_CODE)
  const googleClickId = query.get(QueryStringKey.GOOGLE_CLICK_ID)
  const { track, nativeTracking } = useBranch(registrationInfo)
  const { logFirebaseEvent } = useFirebaseAnalytics(registrationInfo)
  const { trackAppsflyer } = useAppsflyer()
  const tempCityAndVehicleFilled =
    tempFormValues.cityId && tempFormValues.vehicleTypeId

  const { data: vehicleTypes = [] } = useVehicleTypes(
    Number(tempFormValues.cityId)
  )

  const iFrame = query.get(QueryStringKey.IFRAME) || undefined
  const isInIFrame = Number(iFrame) === 1
  const isIframeAFEnabled = should(RemoteConfigKey.USE_IFRAME_AF_WEB_S2S)

  const { trackEvent } = useSensorTracker()

  const getVehicleTypeName = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.label,
    [vehicleTypes]
  )

  const getVehicleTypeWheelType = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.wheel_type || '',
    [vehicleTypes]
  )

  const getCityName = useCallback(
    (cityIdParam: number | string) => {
      const cityObject = (registrationConfig?.city_list || []).find(
        city => city?.id.toString() === cityIdParam.toString()
      )

      return cityObject?.name_en || cityObject?.name
    },
    [registrationConfig]
  )

  const { onError, onSuccess } = options || {}

  const storageAfUserId = localStorage.getItem(LocalStorageKey.AF_USER_ID)

  const { mutateAsync: sendAppsflyerTracking } = useMutation(
    appsflyerApi.sendEvent,
    {
      onError: error => {
        handleError(error)
      },
    }
  )

  const {
    mutateAsync: createRegistration,
    isLoading: isCreatingRegistrationInfo,
    isError: isCreationError,
  } = useMutation(
    () =>
      registrationApi.create({
        cityId,
        ...(fleetInvitationCode
          ? { fleetInvitationCode: fleetInvitationCode }
          : {}),
        ...(tempCityAndVehicleFilled
          ? {
              cityId: tempFormValues.cityId,
              vehicleTypeId: tempFormValues.vehicleTypeId,
            }
          : {}),
        ...(stoneTemplateCode ? { stoneTemplateCode } : {}),
        ...(driverReferCode ? { driverReferCode } : {}),
        ...(googleClickId ? { googleClickId } : {}),
        ...(isInIFrame ? { clientType: 'iframe_web' } : {}),
      }),
    {
      onSuccess: res => {
        const createdReg = res.data
        onSuccess?.(createdReg)
        queryClient.invalidateQueries([CacheKey.REGISTRATION])
        const trackingData = {
          vehicle_type_name: getVehicleTypeName(
            createdReg?.vehicle_type_id.toString() || 0
          ),
          registration_id: createdReg.registration_id,
          country_id: createdReg.country_id.toString(),
          city: getCityName(createdReg.city_id.toString() || 0),
          city_id: createdReg.city_id.toString(),
          vehicle_type_id: createdReg.vehicle_type_id.toString(),
          language: createdReg.language,
          device_type: getMmpDeviceType(),
          user_agent: window.navigator.userAgent,
        }

        // support adding wheel_type to these certain events, see PRD https://huolala.feishu.cn/wiki/XgPJwB4aBiB6AukdUEFcWuyNnce
        const eventName = `${
          MMPEvents.REGISTER_LEAD_CREATED
        }_${getVehicleTypeWheelType(
          createdReg?.vehicle_type_id.toString() || 0
        )}`

        // this section will call mmp tracking events twice, one with wheel_type (lead_created_{wheel_type}) and one without (lead_created)
        if (isInDriverAppWebView()) {
          trackEvent({
            event: SensorsEvent.WEB_SENT_JS_BRIDGE_MMP_TRACKING,
            addedInfo: {
              mmp_event_name: `${MMPEvents.REGISTER_LEAD_CREATED}`,
              ...trackingData,
            },
          })

          // mobile js hook
          nativeTracking(`${MMPEvents.REGISTER_LEAD_CREATED}`, trackingData)
          nativeTracking(eventName, trackingData)
        } else if (isIframeAFEnabled && isInIFrame) {
          // send on iframe client only
          const afUserId = isNotNil(storageAfUserId)
            ? safeParse(storageAfUserId)
            : undefined

          sendAppsflyerTracking({
            registrationId: createdReg.registration_id,
            eventName,
            eventValue: trackingData,
            afUserId,
          })

          sendAppsflyerTracking({
            registrationId: createdReg.registration_id,
            eventName: MMPEvents.REGISTER_LEAD_CREATED,
            eventValue: trackingData,
            afUserId,
          })
        } else {
          track(`${MMPEvents.REGISTER_LEAD_CREATED}`, trackingData)
          track(eventName, trackingData)
          logFirebaseEvent(`${MMPEvents.REGISTER_LEAD_CREATED}`, trackingData)

          logFirebaseEvent(eventName, trackingData)
          trackAppsflyer({
            eventName: `${MMPEvents.REGISTER_LEAD_CREATED}`,
            addedInfo: {
              ...trackingData,
            },
          })

          trackAppsflyer({
            eventName: eventName,
            addedInfo: {
              ...trackingData,
            },
          })

          trackEvent({
            event: SensorsEvent.WEB_SENT_MMP_TRACKING,
            addedInfo: {
              mmp_event_name: `${MMPEvents.REGISTER_LEAD_CREATED}`,
              ...trackingData,
            },
          })
        }

        setTempFormValues({})

        if (rejectionAckedPhones) {
          setAcknowledgements(
            without([createdReg.phone_number], rejectionAckedPhones)
          )
        }
      },
      onError: e => {
        onError?.(e)

        if (!(e instanceof DriverCRMWebAPIError)) {
          handleError(e)
          logout()
          routerHistory.replace(Path.ERROR)
          return
        }

        switch (e.code) {
          case ApiErrors.INVALID_PARAMETER:
            logout()

            if (!tempCityAndVehicleFilled && prevPath === Path.RESUME) {
              toast.error(t('NewRegistration.resume_tooltip'))
            } else {
              routerHistory.replace(Path.ERROR)
            }

            break
          case ApiErrors.FLEET_REGISTRATION_MISMATCH_INVITATION:
            logout()
            break
          case ApiErrors.REGISTRATION_DOES_NOT_MATCH_TYPE:
            logout()

            open({
              imgSrc: errorSvg,
              title: t('VerifyPin.you_have_an_ongoing_fleet_registration'),
              content: t('VerifyPin.please_complete_the_fleet_registration'),
              buttonLabel: t('Actions.got_it') || '',
            })

            break
          case ApiErrors.VERIFIED_DRIVER_EXISTS:
            logout()

            open({
              imgSrc: errorSvg,
              title: t('VerifyPin.you_have_an_account'),
              content: t('VerifyPin.you_have_an_account_prompt_login'),
              buttonLabel: t('Actions.got_it') || '',
            })

            break
          case ApiErrors.RESOURCE_NOT_FOUND:
            logout()
            routerHistory.replace(Path.ERROR, {
              errorType: PathError.FLEET_LINK_EXPIRED,
            })

            break

          case ApiErrors.DRIVER_IS_OFFBOARDING_STATUS:
            logout()
            open({
              imgSrc: errorSvg,
              title: t('Error.title_sorry'),
              content: t('Reject.driver_is_offboarding'),
              buttonLabel: t('Actions.got_it') || '',
            })

            break
          default:
            handleError(e)
            logout()
            routerHistory.replace(Path.ERROR)
            break
        }
      },
    }
  )

  const {
    mutateAsync: patchRegistration,
    isLoading: isPatchingRegistration,
    isError: isPatchError,
  } = useMutation(registrationApi.patch, {
    onSuccess: res => {
      onSuccess?.(res)
      queryClient.invalidateQueries([CacheKey.REGISTRATION])
    },
    onError: e => {
      onError?.(e)
    },
  })

  const {
    mutateAsync: updateDocuments,
    isLoading: isUploadingDocument,
    isError: isUpdateDocError,
  } = useMutation(registrationApi.updateDocuments, {
    onSuccess: () => {
      onSuccess?.()
      queryClient.invalidateQueries([CacheKey.REGISTRATION])
    },
    onError: e => {
      onError?.(e)
    },
  })

  const isRegistrationMutating =
    isCreatingRegistrationInfo || isPatchingRegistration || isUploadingDocument

  useEffect(() => {
    setLoading({
      isRegistrationMutating,
    })
  }, [isRegistrationMutating, setLoading])

  return {
    createRegistration,
    patchRegistration,
    updateDocuments,
    isRegistrationMutating,
    isError: isCreationError || isPatchError || isUpdateDocError,
  }
}
