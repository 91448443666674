export enum CacheKey {
  ONSITE_TRAINING = 'onsite-training',
  ONSITE_TRAINING_CENTERS = 'onsite-training-centers',
  ONSITE_TRAINING_ENROLLMENTS = 'onsite-training-enrollments',
  VIRTUAL_TRAININGS = 'virtual-trainings',
  REGISTRATION = 'registration',
  FORM_CONFIGS = 'form-configs',
  OCR_CONFIG = 'ocr-config',
  OTP_CONFIG = 'otp-config',
  ODOKO_CONFIG = 'odoko-config',
}
