import constate from 'constate'
import { values } from 'ramda'
import { useSetState } from 'react-use'

export const [UsePollingProvider, usePolling, useSetPolling] = constate(
  () => {
    const [pollingState, setPollingState] = useSetState<{
      [keyL: string]: boolean
    }>({})

    return {
      setPolling: setPollingState,
      isPolling: values(pollingState).some(v => v),
    }
  },
  v => v,
  v => v.setPolling // separate the setter as individual hook to avoid meaningless re-render of component that doesnt care about the global state
)
