import {
  focusManager,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import trainingCompletedImg from '@themedImages/training-completed.svg'
import {
  CacheKey,
  Path,
  RemoteConfigKey,
  SensorsEvent,
  SensorsPage,
  envConfig,
} from '@v1/constants'
import { ApiErrors } from '@v1/constants/errors'
import { useRegistration, useSensorTracker } from '@v1/hooks'
import { useModal } from '@v1/hooks/useModal'
import { virtualTrainingApi } from '@v1/services/dcrm'
import { DriverCRMWebAPIError } from '@v1/services/dcrm/errors'
import { VirtualTrainingEnrollment } from '@v1/types'
import { handleError } from '@v1/utils'
import { isEmpty, isNotNil } from 'ramda'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { asyncSleep } from './../utils/asyncSleep'
import { useAuth } from './useAuth'
import { useRegistrationInfo, useRemoteConfig, useSetLoading } from '.'

export const useVirtualTraining = () => {
  const { commit } = useRegistration()
  const { should } = useRemoteConfig()
  const { open } = useModal()
  const { trackEvent } = useSensorTracker()
  const { t } = useTranslation()
  const isFallbackFlowFeatureEnabled = should(
    RemoteConfigKey.USE_VIRTUAL_TRAINING_FALLBACK_FLOW
  )

  const { registrationInfo } = useRegistrationInfo()
  const { isLoggedIn, isRegistrationInitialized } = useAuth()
  const isEnabled = Boolean(isLoggedIn && isRegistrationInitialized)
  const setLoading = useSetLoading()
  const { mutateAsync: passVirtualTraining } = useMutation(
    virtualTrainingApi.passVirtualTraining
  )

  const isFallbackEnabled = Boolean(
    isLoggedIn && isRegistrationInitialized && isFallbackFlowFeatureEnabled
  )

  const { data: isFallbackFlow } = useQuery(
    ['virtual-training-fallback'],
    virtualTrainingApi.checkFallback,
    {
      enabled: isFallbackEnabled,
      onError: e => {
        handleError(e)
        routerHistory.push(Path.ERROR)
      },
    }
  )

  const queryClient = useQueryClient()
  const doSkipVirtualTraining = useCallback(
    async (eid: number) => {
      await passVirtualTraining(eid)
      setLoading({ virtualTraining: false })

      open({
        hasCloseLabel: true,
        imgSrc: trainingCompletedImg,
        title: t('VirtualTraining.success_modal_title'),
        content: t('VirtualTraining.success_modal_content'),
        onDismiss: () => {
          focusManager.setFocused(true)
        },
      })

      trackEvent({
        event: SensorsEvent.REGISTER_PAGE_VIEWED,
        page: SensorsPage.ONLINE_TRAINING_PASSED,
      })

      commit()
    },
    [passVirtualTraining, setLoading, open, t, trackEvent, commit]
  )

  const {
    mutateAsync: createEnrollment,
    data: enrollment,
    isLoading: isVirtualTrainingEnrolling,
  } = useMutation(virtualTrainingApi.enroll, {
    onSuccess: async e => {
      if (
        isNotNil(isFallbackFlow) &&
        isFallbackFlow.fallback &&
        isFallbackFlowFeatureEnabled
      ) {
        await doSkipVirtualTraining(e.id)
      }

      return e
    },
    onError: (e: DriverCRMWebAPIError) => {
      if (!(e instanceof DriverCRMWebAPIError)) {
        handleError(e)
        return
      } else if (e.code === ApiErrors.UNPROCESSABLE_ENTITY) {
        queryClient.invalidateQueries([CacheKey.REGISTRATION])
      } else {
        handleError(e)
        routerHistory.push(Path.ERROR)
      }
    },
  })

  const routerHistory = useHistory()
  const { vehicle_type_id, city_id, status } = registrationInfo || {}
  const { mutateAsync: getResult } = useMutation(virtualTrainingApi.getResult)
  const {
    data = [],
    isLoading: isVirtualTrainingLoading,
    isError: isVirtualTrainingError,
  } = useQuery(
    // virtualTrainings dependent on status ,vehicle_type_id and city_id in backend,trigger refetch on this value updated
    [CacheKey.VIRTUAL_TRAININGS, vehicle_type_id, city_id, status],
    virtualTrainingApi.list,
    {
      enabled: isEnabled,
      onError: e => {
        handleError(e)
        routerHistory.push(Path.ERROR)
      },
    }
  )

  const training = useMemo(() => data?.[0], [data])

  const handleEnroll = useCallback(async () => {
    if (!training) return
    createEnrollment(training.id)
  }, [createEnrollment, training])

  const handleGetResult = useCallback(
    async (
      retryCount = 0,
      retryInterval = envConfig.VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_INTERVAL
    ): Promise<VirtualTrainingEnrollment | null> => {
      if (
        !enrollment ||
        retryCount >
          Number(envConfig.VITE_APP_VIRTUAL_TRAINING_POLLING_RETRY_COUNT)
      ) {
        return null
      }

      const result = await getResult(enrollment.id)

      if (result && result.received_result_at !== null) return result

      await asyncSleep(Number(retryInterval))

      return handleGetResult(retryCount + 1, retryInterval + 1)
    },
    [enrollment, getResult]
  )

  useEffect(() => {
    setLoading({
      isVirtualTrainingLoading: isEnabled && isVirtualTrainingLoading,
      isVirtualTrainingEnrolling,
    })
  }, [
    isVirtualTrainingEnrolling,
    isEnabled,
    isVirtualTrainingLoading,
    setLoading,
  ])

  return {
    typeFormUrl: training?.link,
    isLoading: isEnabled && isVirtualTrainingLoading,
    isAvailable: !isEmpty(data),
    isError: isVirtualTrainingError,
    training,
    enroll: handleEnroll,
    isVirtualTrainingEnrolling,
    getResult: handleGetResult,
    enrollment,
  }
}
