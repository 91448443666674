import { getRuntimeConfig } from '@/configs'
import { ViteAppIDC } from './dataCentre'

export const envConfig = await getRuntimeConfig()

export const mode = String(envConfig.VITE_APP_IDC)

export const [dataCentre, envLevel] = mode.split('-')

export const isProduction = envLevel === 'prd'

export const isDev = envConfig.NODE_ENV === 'development'

export const isStagingOrPreEnv = envLevel === 'stg' || envLevel === 'pre'

export const isIndia = envConfig.VITE_APP_IDC === 'in-prd'

export const isBrazil = envConfig.VITE_APP_IDC === 'br-prd'

export const isLocal = envConfig.VITE_APP_IDC === 'sg-local'

export const legacyEnvMap: { [key in ViteAppIDC]: string } = {
  [ViteAppIDC.SG_LOCAL]: 'local-sg',
  [ViteAppIDC.SG_STG]: 'stg-sg',
  [ViteAppIDC.SG_PRE]: 'pre-sg',
  [ViteAppIDC.SG_PRE_UNCLED]: 'pre-sg-uncled',
  [ViteAppIDC.SG_STG_UNCLED]: 'stg-sg-uncled',
  [ViteAppIDC.SG_PROD]: 'prod-sg',
  [ViteAppIDC.BR_PROD]: 'prod-br',
  [ViteAppIDC.IN_PROD]: 'prod-bom',
}
