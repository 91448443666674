import {
  OnsiteTrainingCenter,
  OnsiteTrainingEnrollment,
  OnsiteTrainingSession,
} from '@v1/types'
import { isNil } from 'ramda'

import { apiClient } from './apiClient'

const list = async (options?: { training_center_id?: number }) => {
  const { data } = await apiClient.get<{
    data: OnsiteTrainingSession[]
    links: unknown[]
  }>('/onsite-trainings', {
    params: {
      ...(!isNil(options?.training_center_id)
        ? { training_center_id: options?.training_center_id }
        : {}),
    },
  })

  return data.data
}

const listCenters = async () => {
  const { data } = await apiClient.get<{
    data: OnsiteTrainingCenter[]
    link: unknown[]
  }>('/training-centers')

  return data.data
}

const enroll = async (sessionId: number) => {
  const { data } = await apiClient.post<OnsiteTrainingEnrollment>(
    `/onsite-trainings/${sessionId}/enrollments`
  )

  return data
}

const getEnrollments = async () => {
  const { data } = await apiClient.get<{
    data: OnsiteTrainingEnrollment[]
    links: unknown[]
  }>('/onsite-training-enrollments')

  return data
}

export const onsiteTrainingApi = { list, enroll, listCenters, getEnrollments }
