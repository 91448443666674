import {
  VirtualTrainingEnrollment,
  VirtualTrainingListResponse,
} from '@v1/types'

import { apiClient } from './apiClient'

const enroll = async (virtualTrainingId: number) => {
  const { data } = await apiClient.post<VirtualTrainingEnrollment>(
    `/virtual-trainings/${virtualTrainingId}/enrollments`
  )

  return data
}

const list = async () => {
  const {
    data: { data },
  } = await apiClient.get<VirtualTrainingListResponse>('/virtual-trainings')

  return data
}

const getResult = async (enrollmentId: number) => {
  const { data } = await apiClient.get<VirtualTrainingEnrollment>(
    `/virtual-training-enrollments/${enrollmentId}`
  )

  return data
}

const checkFallback = async () => {
  const { data } = await apiClient.get<{ fallback: boolean }>(
    '/check-virtual-training-fallback/'
  )

  return data
}

const passVirtualTraining = async (enrollmentId: number) => {
  const { data } = await apiClient.post<VirtualTrainingEnrollment>(
    `/virtual-training-enrollments/${enrollmentId}/pass`
  )

  return data
}

export const virtualTrainingApi = {
  enroll,
  list,
  getResult,
  checkFallback,
  passVirtualTraining,
}
