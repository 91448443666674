export enum ApiErrors {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  VERIFIED_DRIVER_EXISTS = 'VERIFIED_DRIVER_EXISTS',
  FLEET_REGISTRATION_MISMATCH_INVITATION = 'FLEET_REGISTRATION_MISMATCH_INVITATION',
  REGISTRATION_DOES_NOT_MATCH_TYPE = 'REGISTRATION_DOES_NOT_MATCH_TYPE',
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  EXCEED_LIMIT = 'EXCEED_LIMIT',
  INVALID_OTP = 'INVALID_OTP',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  DRIVER_IS_OFFBOARDING_STATUS = 'DRIVER_IS_OFFBOARDING_STATUS',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
}
