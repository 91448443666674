import constate from 'constate'
import { useSetState } from 'react-use'

export const [UseTempFormValueProvider, useTempFormValue] = constate(() => {
  const [tempFormValues, setTempFormValues] = useSetState<{
    cityId?: string
    phoneNumber?: string // without area code
    vehicleTypeId?: string
  }>()

  return {
    tempFormValues,
    setTempFormValues,
  }
})
