import { useQuery } from '@tanstack/react-query'
import { Path } from '@v1/constants'
import { ApiErrors } from '@v1/constants/errors'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { DriverCRMWebAPIError } from '@v1/services/dcrm/errors'
import { registrationConfigApi } from '@v1/services/dcrm/registrationConfigs'
import { handleError } from '@v1/utils'
import { isNil } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

import {
  useFleet,
  useI18n,
  useModal,
  useQueryParam,
  useRegion,
  useSetLoading,
} from '.'

export const useRegistrationConfig = (options?: {
  cityId?: string | number
}) => {
  const { fleetInfo, isLoading: isFleetLoading } = useFleet()
  const { initialized: i18NInitialized, hlang } = useI18n()
  const { marketId, cityId } = useRegion()
  const history = useHistory()
  const query = useQueryParam()
  const deviceId = query.get(QueryStringKey.DEVICE_ID) || undefined
  const setLoading = useSetLoading()
  const params = useQueryParam()
  const fleetInvitationCode = params.get(QueryStringKey.FLEET_INV_CODE)
  const isFleet = !isNil(fleetInvitationCode)
  const queryCityId = String(options?.cityId ? options?.cityId : cityId)
  const queryMarketId = fleetInfo?.country_id || marketId
  const { openUnexpected } = useModal()

  const isEnabled = i18NInitialized && !isFleetLoading
  const { data: registrationConfig, isLoading: isLoadingRegConfig } = useQuery(
    ['registration-config', queryMarketId, hlang, queryCityId],
    () =>
      registrationConfigApi.get({
        deviceId,
        marketId: queryMarketId,
        hlang,
        cityId: queryCityId,
      }),
    {
      enabled: isEnabled,
      retry: (count, error: DriverCRMWebAPIError) => {
        switch (error.code) {
          case ApiErrors.INVALID_PARAMETER:
            openUnexpected()
            handleError(error)
            return false
          default:
            break
        }

        if (count < 5) return true
        history.replace(Path.ERROR)
        handleError(error)
        return false
      },
      keepPreviousData: true,
    }
  )

  const links = useMemo(() => {
    const allLinks = registrationConfig?.links
    if (!allLinks) return {}
    return {
      helpLink: isFleet ? allLinks.fleet_help_link : allLinks.help_link,
      termsLink: isFleet
        ? allLinks.fleet_terms_and_conditions
        : allLinks.terms_and_conditions,
      privacyPolicyLink: isFleet
        ? allLinks.fleet_privacy_policy
        : allLinks.privacy_policy,
    }
  }, [registrationConfig, isFleet])

  useEffect(() => {
    setLoading({
      isLoadingRegConfig: isEnabled && isLoadingRegConfig,
    })
  }, [isLoadingRegConfig, isEnabled, setLoading])

  return {
    isLoadingRegConfig: isEnabled && isLoadingRegConfig,
    registrationConfig,
    links,
  }
}
