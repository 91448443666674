import { OtpTypeV2 } from './otp'
import { Path } from '.'

export enum SensorsEvent {
  REGISTER_PAGE_VIEWED = 'register_page_viewed',
  REGISTER_PAGE_CONFIRM_CLICKED = 'register_page_confirm_clicked',
  REGISTER_SMS_VERIFICATION_CLICKED = 'register_sms_verification_clicked',
  REGISTER_TRAINING_SELECTION_SELECTED = 'register_training_selection_selected',
  REGISTER_VERIFIED_OPEN_APPSTORE = 'register_verified_open_appstore',
  REGISTER_HELP_CLICKED = 'register_help_clicked',
  REGISTER_RESCHEDULE_CLICKED = 'register_reschedule_clicked',
  REGISTER_REDO_TRAINING_CLICKED = 'register_redo_training_clicked',
  REGISTER_RESUBMIT_PHOTO_CLICKED = 'register_resubmit_photo_clicked',
  REGISTER_VALIDATION_ERROR = 'register_validation_error',
  REGISTER_PHOTO_CLICKED = 'register_photo_clicked',
  REGISTER_PAGE_BUTTON_CLICKED = 'register_page_button_clicked',
  REGISTER_PHOTO_CAMERA_TAPPED = 'register_photo_camera_tapped',
  REGISTER_PHOTO_GALLERY_TAPPED = 'register_photo_gallery_tapped',
  REGISTER_PHOTO_UPLOAD_STARTED = 'register_photo_upload_started',
  REGISTER_PHOTO_UPLOAD_SUCCEDEED = 'register_photo_upload_succeeded',
  REGISTER_ORC_REJECTED_PHOTO_TAPPED = 'register_ocr_rejected_photo_tapped',
  REGISTER_PHOTO_DESC_POPUP_CLOSE_TAPPED = 'register_photo_desc_popup_close_tapped',
  VERIFY_OTP_PAGE_VIEWED = 'verify_otp_page_view',
  RESEND_SMS_VERIFICATION_TAPPED = 'resend_sms_verification_tapped',
  RESEND_CALL_VERIFICATION_TAPPED = 'resend_call_verification_tapped',
  OTP_VALIDATION_ERROR = 'otp_validation_error',
  REGISTER_RESUME_TAPPED = 'register_resume_tapped',
  RESUME_REGISTER_VIEWED = 'resume_register_viewed',
  RESUME_CONTINUE_TAPPED = 'resume_continue_tapped',
  WEB_SENT_JS_BRIDGE_MMP_TRACKING = 'web_sent_js_bridge_mmp_tracking',
  WEB_SENT_MMP_TRACKING = 'web_sent_mmp_tracking',
  JOIN_PHONE_CLICK = 'join_phone_click',
  JOIN_CITYLIST_CLICK = 'join_citylist_click',
  JOIN_CITY_CLICK = 'join_city_click',
  JOIN_VEHICLE_TYPE_CLICK = 'join_vehicle_type_click',
  JOIN_VEHICLE_TYPE_SELECT = 'join_vehicle_type_select',
  DIDNT_RECEIVE_CODE_TAPPED = 'didnt_receive_code_tapped',
  VERIFICATION_CODE_ENTERED = 'verification_code_entered',
  DEBUG_20240306 = 'driver_registration_debug_20240306',
  REGISTER_RC_INVALID = 'register_rc_invalid',
  DOCUMENT_UPLOAD = 'reg_web_document_upload',
  CALLBACK_FROM_APP = 'reg_web_callback_from_app_photo',
}

export const OTP_SCENARIO_SENSORS_MAP = {
  [OtpTypeV2.VIBER]: 'viber_verification',
  [OtpTypeV2.MISSED_CALL]: 'missedcall_verification',
  [OtpTypeV2.SMS]: 'sms_verification',
  [OtpTypeV2.VOICE]: 'call_verification',
}

export const OTP_TYPE_SENSORS_MAP = {
  [OtpTypeV2.VIBER]: 'viber_otp',
  [OtpTypeV2.MISSED_CALL]: 'missedcall_otp',
  [OtpTypeV2.SMS]: 'sms_otp',
  [OtpTypeV2.VOICE]: 'call_otp',
}

export enum SensorsPage {
  LANDING_PAGE = 'landing_page',
  LANDING_PAGE_MOBILE_NUMBER_INPUT = 'mobile_number',
  SMS_VERIFICATION = 'sms_verification',
  BASIC_INFO = 'basic_info',
  PERSONAL_INFO = 'personal_info',
  VEHICLE_INFO = 'vehicle_info',
  TRAINING_SELECTION = 'training_selection',
  ONLINE_TRAINING_START = 'online_training_start',
  ONLINE_TRAINING_FAILED_TRY_AGAIN = 'online_training_failed_try_again_modal',
  ONLINE_TRAINING_PASSED = 'online_training_passed_modal',
  ONLINE_TRAINING_SCHEDULE_NOW = 'online_training_schedule_now_modal',
  ONLINE_TRAINING_FAIL_REJECT = 'online_training_fail_reject_modal',
  ONSITE_TRAINING_START = 'onsite_training_start',
  ONSITE_TRAINING_SCHEDULED = 'onsite_training_scheduled_modal',
  ADDITIONAL_INFO = 'additional_info',
  UPLOAD_PHOTO = 'upload_photo',
  ADDITIONAL_INFO_RESCHEDULE_TRAINING = 'additional_info_reschedule_training',
  SUMMARY_INFO_RESCHEDULE_TRAINING = 'summary_reschedule_training',
  REGISTER_SUMMARY_COMPLETE = 'summary_complete',
  REGISTER_SUMMARY_INCOMPLETE = 'summary_incomplete',
  RESUBMIT_PHOTO = 'resubmit_photo',
  VERIFIED_FIRST_TIME_PASSWORD = 'verified_first_time_password',
  VERIFIED_PASSWORD_COPIED = 'verified_password_copied',
  VERIFIED_DOWNLOAD_APP = 'verified_download_app',
  REJECTED_LEAD = 'rejected_lead',
  REJECTED_TRAINING = 'rejected_by_training',
  CONFIG_ERROR = 'config_error',
  RESUME = 'resume',
}

export const PATH_SENSORS_WHITELIST: Path[] = [
  Path.LANDING,
  Path.OTP_VERIFICATION,
  Path.BASIC_INFO,
  Path.PERSONAL_INFO,
  Path.VEHICLE_INFO,
]

export const PATH_SENSORS_PAGE_MAP: Record<string, string> = {
  [Path.LANDING]: SensorsPage.LANDING_PAGE_MOBILE_NUMBER_INPUT,
  [Path.OTP_VERIFICATION]: SensorsPage.SMS_VERIFICATION,
  [Path.BASIC_INFO]: SensorsPage.BASIC_INFO,
  [Path.PERSONAL_INFO]: SensorsPage.PERSONAL_INFO,
  [Path.ADDITIONAL_INFO]: SensorsPage.ADDITIONAL_INFO,
  [Path.VEHICLE_INFO]: SensorsPage.VEHICLE_INFO,
  [Path.TRAINING_SELECTION]: SensorsPage.TRAINING_SELECTION,
  [Path.VIRTUAL_TRAINING]: SensorsPage.ONLINE_TRAINING_START,
  [Path.ONSITE_TRAINING]: SensorsPage.ONSITE_TRAINING_START,
  [Path.RESUBMIT]: SensorsPage.RESUBMIT_PHOTO,
  [Path.RESUME]: SensorsPage.RESUME,
  [Path.ERROR]: SensorsPage.CONFIG_ERROR,
}
