export enum OtpType {
  SMS = 'sms',
  VOICE = 'voice',
}

export enum OtpTypeV2 {
  SMS = 'SMS',
  VOICE = 'Voice',
  VIBER = 'Viber',
  MISSED_CALL = 'MissedCall',
}

export const DEFAULT_OTP_COOLDOWN = 60
