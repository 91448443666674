import { useQuery } from '@tanstack/react-query'
import errorSvg from '@themedImages/error.svg'
import { CacheKey, Path, PathError } from '@v1/constants'
import { ApiErrors } from '@v1/constants/errors'
import { LocalStorageKey } from '@v1/constants/localStorageKey'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { registrationApi } from '@v1/services/dcrm'
import { DriverCRMWebAPIError } from '@v1/services/dcrm/errors'
import { handleError } from '@v1/utils'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'

import {
  useAuth,
  useModal,
  useQueryParam,
  useRegion,
  useSetLoading,
  useTempFormValue,
} from '.'

export const useRegistrationInfo = () => {
  const { marketId } = useRegion()
  const { open } = useModal()
  const { t } = useTranslation()
  const params = useQueryParam()
  const fleetInvitationCode = params.get(QueryStringKey.FLEET_INV_CODE)
  const routerHistory = useHistory()
  const routerLocation = useLocation<{ from: Path }>()
  const { logout } = useAuth()
  const { tempFormValues } = useTempFormValue()
  const setLoading = useSetLoading()
  const tempCityAndVehicleFilled =
    tempFormValues.cityId && tempFormValues.vehicleTypeId

  const storageToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN)

  const { data, ...rest } = useQuery(
    [CacheKey.REGISTRATION, marketId, fleetInvitationCode],
    () =>
      registrationApi.get({
        hcountry: marketId,
        ...(isNil(fleetInvitationCode)
          ? {}
          : { fleetInvitationCode: fleetInvitationCode }),
      }),
    {
      enabled: Boolean(storageToken), // pjone-15721 prevent race condition of checking the token from useAuth so directly check local storage
      onError: e => {
        if (!(e instanceof DriverCRMWebAPIError)) {
          handleError(e)
          routerHistory.replace(Path.ERROR)
          return
        }

        switch (e.code) {
          case ApiErrors.INVALID_PARAMETER:
            logout()
            if (
              !tempCityAndVehicleFilled &&
              routerLocation.state?.from === Path.RESUME
            ) {
              toast.error(t('NewRegistration.resume_tooltip'))
            } else {
              routerHistory.replace(Path.ERROR)
            }

            break
          case ApiErrors.FLEET_REGISTRATION_MISMATCH_INVITATION:
            logout()
            break
          case ApiErrors.REGISTRATION_DOES_NOT_MATCH_TYPE:
            open({
              imgSrc: errorSvg,
              title: t('VerifyPin.you_have_an_ongoing_fleet_registration'),
              content: t('VerifyPin.please_complete_the_fleet_registration'),
              buttonLabel: t('Actions.got_it') || '',
              onDismiss: logout,
            })

            break
          case ApiErrors.VERIFIED_DRIVER_EXISTS:
            open({
              imgSrc: errorSvg,
              title: t('VerifyPin.you_have_an_account'),
              content: t('VerifyPin.you_have_an_account_prompt_login'),
              buttonLabel: t('Actions.got_it') || '',
              onDismiss: logout,
            })

            break
          case ApiErrors.RESOURCE_NOT_FOUND:
            routerHistory.replace(Path.ERROR, {
              errorType: PathError.FLEET_LINK_EXPIRED,
            })

            break
          default:
            handleError(e)
            routerHistory.replace(Path.ERROR)
            break
        }

        return e
      },
    }
  )

  useEffect(() => {
    setLoading({
      isRegistrationInfoLoading: rest.isFetching,
    })
  }, [setLoading, rest.isFetching])

  return {
    registrationInfo: data?.data?.[0] || undefined,
    ...rest,
  }
}
