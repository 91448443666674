import { History, LocationDescriptor, LocationDescriptorObject } from 'history'
import qs from 'qs'
import { pick } from 'ramda'

type LocationState = History.LocationState

type CreateHistory<O, H> = (options?: O) => History & H

const preserveQueryParameters = (
  history: History,
  preserve: string[],
  location: LocationDescriptorObject
): LocationDescriptorObject => {
  const currentQuery = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })

  if (currentQuery) {
    const preservedQuery: { [key: string]: unknown } = {
      ...pick(preserve, currentQuery),
      ...(location.search
        ? qs.parse(location.search, { ignoreQueryPrefix: true })
        : {}),
    }

    // eslint-disable-next-line fp/no-mutation
    location.search = `?${qs.stringify(preservedQuery)}`
  }

  return location
}

const createLocationDescriptorObject = (
  location: LocationDescriptor,
  state?: LocationState
): LocationDescriptorObject =>
  typeof location === 'string' ? { pathname: location, state } : location

export const createPreserveQueryHistory =
  <O, H>(
    createHistory: CreateHistory<O, H>,
    queryParameters: string[]
  ): CreateHistory<O, H> =>
  (options?: O) => {
    const history = createHistory(options)
    const oldPush = history.push
    const oldReplace = history.replace
    // eslint-disable-next-line fp/no-mutation
    history.push = (path: LocationDescriptor, state?: LocationState) =>
      oldPush.apply(history, [
        preserveQueryParameters(
          history,
          queryParameters,
          createLocationDescriptorObject(path, state)
        ),
      ])

    // eslint-disable-next-line fp/no-mutation
    history.replace = (path: LocationDescriptor, state?: LocationState) =>
      oldReplace.apply(history, [
        preserveQueryParameters(
          history,
          queryParameters,
          createLocationDescriptorObject(path, state)
        ),
      ])

    return history
  }
