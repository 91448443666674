export const COUNTRY_PHONE_CONFIG: {
  [countryId in number]: {
    dialCode: string
    countryCode: string
    phoneMaxLength: number
    phoneRegex: string
  }
} = {
  10000: {
    dialCode: '+91',
    countryCode: 'IN',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{9}$',
  },
  20000: {
    dialCode: '+55',
    countryCode: 'BR',
    phoneMaxLength: 11,
    phoneRegex: '^[1-9][0-9]{10}$',
  },
  30000: {
    dialCode: '+65',
    countryCode: 'SG',
    phoneMaxLength: 8,
    phoneRegex: '^[1-9][0-9]{7}$',
  },
  40000: {
    dialCode: '+60',
    countryCode: 'MY',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{8,9}$',
  },
  50000: {
    dialCode: '+63',
    countryCode: 'PH',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{9}$',
  },
  60000: {
    dialCode: '+52',
    countryCode: 'MX',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{9}$',
  },
  70000: {
    dialCode: '+1',
    countryCode: 'US',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{9}$',
  },
  80000: {
    dialCode: '+886',
    countryCode: 'TW',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{8,9}$',
  },
  90000: {
    dialCode: '+852',
    countryCode: 'HK',
    phoneMaxLength: 8,
    phoneRegex: '^[1-9][0-9]{7}$',
  },
  100000: {
    dialCode: '+84',
    countryCode: 'VN',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{8,9}$',
  },
  110000: {
    dialCode: '+66',
    countryCode: 'TH',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{8,9}$',
  },
  120000: {
    dialCode: '+62',
    countryCode: 'ID',
    phoneMaxLength: 13,
    phoneRegex: '^[1-9][0-9]{12}$',
  },
  130000: {
    dialCode: '+880',
    countryCode: 'BDT',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{10}$',
  },
  150000: {
    dialCode: '+81',
    countryCode: 'JP',
    phoneMaxLength: 11,
    phoneRegex: '^[1-9][0-9]{8,9,10}$',
  },
  160000: {
    dialCode: '+90',
    countryCode: 'TR',
    phoneMaxLength: 10,
    phoneRegex: '^[1-9][0-9]{10}$',
  },
}
