import { replace, split } from 'ramda'

export const toI18nLocal = (lang: string) => {
  const withReplacedConnector = replace('_', '-', lang)
  const [first, second] = split('-', withReplacedConnector)

  return second
    ? `${first?.toLowerCase()}-${second?.toUpperCase()}`
    : first?.toLowerCase()
}

export const toHlang = (lang: string) => {
  const withReplacedConnector = replace('-', '_', lang)
  const [first, second] = split('_', withReplacedConnector)

  return second
    ? `${first?.toLowerCase()}_${second?.toLowerCase()}`
    : first?.toLowerCase()
}
