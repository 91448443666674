import {
  DataCentre,
  DataCentrePrefixMap,
  SensorsEvent,
  SensorsPage,
  countryCodeMap,
  dataCentre,
  envConfig,
  envLevel,
  isProduction,
} from '@v1/constants'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { useCallback } from 'react'

import { useRegion } from './useRegion'
import { getQueryParams } from '.'

interface SensorInfo {
  event: SensorsEvent
  page?: SensorsPage | string
  addedInfo?: KeyValuePair
}

interface KeyValuePair {
  [name: string]: unknown
}

let initialized = false
let instance: typeof import('sa-sdk-javascript/sensorsdata.min').default
const getInstance = async () => {
  if (!instance) {
    // eslint-disable-next-line fp/no-mutation
    instance = (await import('sa-sdk-javascript')).default
  }

  return instance
}

const initialize = async () => {
  const sensors = await getInstance()
  sensors.init({
    server_url: String(envConfig.VITE_APP_SENSORS_SERVER_URL || ''),
    show_log: !isProduction,
    is_track_device_id: true,
    is_track_single_page: true,
  })

  // eslint-disable-next-line fp/no-mutation
  initialized = true
}

export const useSensorTracker = () => {
  const { marketId, hasCityId, cityId } = useRegion()
  const queryClientType = getQueryParams().get(QueryStringKey.CLIENT_TYPE)

  const trackEvent = useCallback(
    async (sensor: SensorInfo) => {
      const sensors = await getInstance()
      if (!initialized) {
        await initialize()
      }

      sensors.track(sensor.event, {
        page: sensor.page,
        env: envLevel,
        data_center: DataCentrePrefixMap[dataCentre as DataCentre],
        country: countryCodeMap[marketId || ''],
        country_code: marketId,
        source: queryClientType || 'web',
        city: hasCityId ? cityId : undefined,
        ...sensor.addedInfo,
      })
    },
    [marketId, queryClientType, hasCityId, cityId]
  )

  return { trackEvent, initialized, getInstance, initialize }
}
