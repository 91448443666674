export enum ZIndex {
  HIDE = -1,
  AUTO = 'auto',
  BASE = 0,
  DOCKED = 10,
  STICKY = 700,
  DROPDOWN = 800,
  BANNER = 900,
  OVERLAY = 1300,
  MODAL = 1400,
  POPOVER = 1500,
  TOAST = 1700,
  TOOLTIP = 1800,
  LOADING = 9999,
}
