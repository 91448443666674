export enum RegistrationStatus {
  CREATED = 1,
  BASIC_INFO_SUBMITTED = 2,
  PERSONAL_INFO_SUBMITTED = 3,
  VEHICLE_INFO_SUBMITTED = 4,
  VIRTUAL_TRAINING_STARTED = 5,
  ONSITE_TRAINING_SCHEDULED = 6,
  TRAINING_ABSENT = 7,
  TRAINING_PASSED = 8,
  TRAINING_FAILED = 9,
  TRAINING_CANCELED = 10,
  APPROVED = 11,
  REJECTED = 12,
}
