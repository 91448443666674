import { useLoading } from '@v1/hooks'
import React from 'react'

import { PurePageLoading } from './PurePageLoading'

export const PageLoading = () => {
  const { isLoading } = useLoading()

  return <PurePageLoading isLoading={isLoading} />
}
