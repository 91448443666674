import { Path } from './path'

export enum StepType {
  ADDITIONAL_INFO = 'additional_info',
  PERSONAL_INFO = 'personal_info',
  VEHICLE_INFO = 'vehicle_info',
  TRAINING = 'training',
  CREATE_ACCOUNT = 'create_account',
}
export const getStepFromPath = (path: Path) => {
  switch (path) {
    case Path.BASIC_INFO:
      return StepType.CREATE_ACCOUNT
    case Path.PERSONAL_INFO:
      return StepType.PERSONAL_INFO
    case Path.VEHICLE_INFO:
      return StepType.VEHICLE_INFO
    case Path.ADDITIONAL_INFO:
      return StepType.ADDITIONAL_INFO
    case Path.TRAINING_SELECTION:
    case Path.VIRTUAL_TRAINING:
    case Path.ONSITE_TRAINING:
      return StepType.TRAINING
    default:
      return null
  }
}
