import { QueryStringKey } from '@v1/constants/queryStringKey'
import { InternalAxiosRequestConfig } from 'axios'

import { shallowRemoveEmptyProps } from '.'

export const DCRM_KEYS: string[] = [
  QueryStringKey.FLEET_INV_CODE,
  QueryStringKey.HLANG,
  QueryStringKey.DEVICE_ID,
  QueryStringKey.HTZONE,
  QueryStringKey.CLIENT_TYPE,
  QueryStringKey.HCOUNTRY,
  QueryStringKey.CITY_ID,
  QueryStringKey.OS_TYPE,
  QueryStringKey.APP_ID,
  QueryStringKey.APP_VERSION,
  QueryStringKey.REVISION,
  QueryStringKey.DRIVER_REFERRAL_CODE,
]

export const registrationInterceptor = (config: InternalAxiosRequestConfig) => {
  const urlSearchParams = new URLSearchParams(window.location.search)

  const utmParams = shallowRemoveEmptyProps(
    Object.fromEntries(
      [...urlSearchParams].filter(([key]) => DCRM_KEYS.includes(key))
    )
  )

  // eslint-disable-next-line fp/no-mutation
  config.params = {
    [QueryStringKey.CLIENT_TYPE]: 'web',
    ...utmParams,
    ...config.params,
  }

  return config
}
