import { logBranchEventTracking } from '@/v1/utils/nativeBridge'
import { isInDriverAppWebView } from '@hll/driver-webview-bridge-js'
import { envConfig, isProduction } from '@v1/constants'
import { MMPEvents, Registration, TrackingData } from '@v1/types'
import {
  getMmpDeviceType,
  handleError,
  shallowRemoveEmptyProps,
} from '@v1/utils'
import { useCallback } from 'react'

import { useTempFormValue, useVehicleTypes } from './'
import { useRegion } from './useRegion'
import { useRegistrationConfig } from './useRegistrationConfig'

let initialized = false
let instance: typeof import('branch-sdk')
const getInstance = async () => {
  if (!instance) {
    // eslint-disable-next-line fp/no-mutation
    instance = (await import('branch-sdk')).default
  }

  return instance
}

const initialize = async () => {
  const branch = await getInstance()
  branch.init(
    envConfig.VITE_APP_BRANCH_KEY,
    {
      no_journeys: isInDriverAppWebView(),
    },
    (error, data) => {
      if (error) {
        handleError(error)
      }

      if (!isProduction) {
        // eslint-disable-next-line no-console
        console.log('%c%s', 'color: #408059', 'branch.io initialized', data)
      }
    }
  )

  // eslint-disable-next-line fp/no-mutation
  initialized = true
}

export const useBranch = (registrationInfo?: Registration) => {
  const { registrationConfig } = useRegistrationConfig()
  const { cityId } = useRegion()
  const { tempFormValues } = useTempFormValue()

  const getCityName = useCallback(
    (cityIdParam: number | string) => {
      const cityObject = (registrationConfig?.city_list || []).find(
        city => city?.id.toString() === cityIdParam.toString()
      )

      return cityObject?.name_en || cityObject?.name
    },
    [registrationConfig]
  )

  const { data: vehicleTypes = [] } = useVehicleTypes(
    Number(tempFormValues.cityId) || cityId
  )

  const getVehicleTypeName = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.label,
    [vehicleTypes]
  )

  const getVehicleTypeWheelType = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.wheel_type || '',
    [vehicleTypes]
  )

  const track = useCallback(
    async (event: string, metaData?: TrackingData['event_data']) => {
      const branchInstance = await getInstance()
      if (!initialized) {
        await initialize()
      }

      const eventParams = shallowRemoveEmptyProps({
        registration_id: registrationInfo?.registration_id,
        country_id: registrationInfo?.country_id.toString(),
        device_type: getMmpDeviceType(),
        city_id: registrationInfo?.city_id.toString(),
        vehicle_type_id: registrationInfo?.vehicle_type_id.toString(),
        city: getCityName(metaData?.city_id || registrationInfo?.city_id || 0),
        vehicle_type_name: getVehicleTypeName(
          metaData?.vehicle_type_id || registrationInfo?.vehicle_type_id || 0
        ),
        language: registrationInfo?.language,
        ...(metaData || {}),
      })

      // support adding wheel_type to these certain events, see PRD https://huolala.feishu.cn/wiki/XgPJwB4aBiB6AukdUEFcWuyNnce
      const isWheelTypeEvent =
        event === MMPEvents.REGISTER_COMPLETED ||
        event === MMPEvents.REGISTER_LEAD_VERIFIED

      if (isWheelTypeEvent) {
        // call tracking with wheel_type
        branchInstance.track(
          `${event}_${getVehicleTypeWheelType(
            registrationInfo?.vehicle_type_id || 0
          )}`,
          eventParams
        )
      }

      branchInstance.track(isWheelTypeEvent ? `${event}` : event, eventParams)
    },
    [
      getCityName,
      registrationInfo?.city_id,
      registrationInfo?.country_id,
      registrationInfo?.registration_id,
      registrationInfo?.vehicle_type_id,
      registrationInfo?.language,
      getVehicleTypeName,
      getVehicleTypeWheelType,
    ]
  )

  const nativeTracking = useCallback(
    (event: string, metaData?: TrackingData['event_data']) => {
      const eventData = shallowRemoveEmptyProps({
        registration_id: registrationInfo?.registration_id,
        country_id: registrationInfo?.country_id.toString(),
        device_type: getMmpDeviceType(),
        city_id: registrationInfo?.city_id.toString(),
        vehicle_type_id: registrationInfo?.vehicle_type_id.toString(),
        city: getCityName(metaData?.city_id || registrationInfo?.city_id || 0),
        vehicle_type_name: getVehicleTypeName(
          metaData?.vehicle_type_id || registrationInfo?.vehicle_type_id || 0
        ),
        language: registrationInfo?.language,
        ...(metaData || {}),
      })

      // support adding wheel_type to these certain events, see PRD https://huolala.feishu.cn/wiki/XgPJwB4aBiB6AukdUEFcWuyNnce
      const isWheelTypeEvent =
        event === MMPEvents.REGISTER_COMPLETED ||
        event === MMPEvents.REGISTER_LEAD_VERIFIED

      if (isWheelTypeEvent) {
        // call tracking with wheel_type
        logBranchEventTracking({
          event_name: `${event}_${getVehicleTypeWheelType(
            registrationInfo?.vehicle_type_id || 0
          )}`,
          event_data: eventData,
        })
      }

      logBranchEventTracking({
        event_name: isWheelTypeEvent ? `${event}` : event,
        event_data: eventData,
      })
    },
    [
      getCityName,
      registrationInfo?.city_id,
      registrationInfo?.country_id,
      registrationInfo?.registration_id,
      registrationInfo?.vehicle_type_id,
      registrationInfo?.language,
      getVehicleTypeName,
      getVehicleTypeWheelType,
    ]
  )

  return {
    track,
    initialize,
    initialized,
    getInstance,
    nativeTracking,
  }
}
