import constate from 'constate'
import { values } from 'ramda'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router'
import { useSetState } from 'react-use'

export const [UseLoadingProvider, useLoading, useSetLoading] = constate(
  () => {
    const browserLocation = useLocation()
    const [loadingState, setLoadingState] = useSetState<{
      [keyL: string]: boolean
    }>({})

    const clearAll = useCallback(() => {
      setLoadingState({})
    }, [setLoadingState])

    useEffect(() => {
      clearAll()
    }, [browserLocation.pathname, clearAll])

    return {
      setLoading: setLoadingState,
      clearAll,
      isLoading: values(loadingState).some(v => v),
      loadingState,
    }
  },
  returnValues => returnValues,
  returnValues => returnValues.setLoading
)
