import { DocumentType } from '@v1/constants'
import {
  DocumentFieldValues,
  Field,
  Registration,
  RegistrationFormFieldParam,
} from '@v1/types'
import { shallowRemoveEmptyProps } from '@v1/utils'
import { AxiosResponse } from 'axios'
import { is } from 'ramda'

import { apiClient } from './apiClient'

export type RegistrationUpdateParam = Partial<RegistrationFormFieldParam>

const get = ({
  hcountry,
  fleetInvitationCode,
}: {
  hcountry?: number
  fleetInvitationCode?: string | number
}) =>
  apiClient.get<Registration[]>('/driver/registrations', {
    params: {
      hcountry,
      invitation_code: fleetInvitationCode,
    },
  })

const create = ({
  cityId,
  fleetInvitationCode,
  vehicleTypeId,
  stoneTemplateCode,
  driverReferCode,
  clientType,
}: {
  cityId: string | number
  timezone?: string
  fleetInvitationCode?: string | number
  vehicleTypeId?: string | number
  stoneTemplateCode?: string
  driverReferCode?: string
  clientType?: string
}) =>
  apiClient.post<Record<string, unknown>, AxiosResponse<Registration>>(
    '/registrations',
    {
      city_id: cityId,
      invitation_code: fleetInvitationCode,
      vehicle_type_id: vehicleTypeId,
      client_type: clientType,
    },
    {
      params: {
        code: stoneTemplateCode,
        ic: driverReferCode,
      },
    }
  )

/** to get tracking id */
const getDriverLead = () => apiClient.get('/driver-lead')

const patch = async ({
  registrationId,
  changes,
}: {
  registrationId: string | number
  changes: RegistrationUpdateParam
}) => {
  const { data } = await apiClient.patch<
    RegistrationUpdateParam,
    AxiosResponse<Registration>
  >(`/registrations/${registrationId}`, shallowRemoveEmptyProps(changes))

  return data
}

const invalidateFirstTimeLoginCode = (registrationId: string | number) =>
  apiClient.patch(
    `/registrations/${registrationId}/invalidate-first-time-login-code`
  )

// Note: Resubmit documents
const updateDocuments = (
  values: Record<Field['field_key'], DocumentFieldValues>
) => apiClient.patch<Field['field_key'][]>('/documents', values)

const referralInfo = async ({
  stoneTemplateCode,
}: {
  stoneTemplateCode?: string
}) => {
  const { data } = await apiClient.get<{
    data: {
      totalCurrency: number
      currencySymbol: string
    }
  }>('/driver-referral-reward-info', {
    params: {
      template_code: stoneTemplateCode,
    },
  })

  return data.data
}

const submitPendingDocument = async (
  field: Field['field_key'],
  documentType: DocumentType,
  value: DocumentFieldValues
) => {
  const { data } = await apiClient.post('/registrations/document', {
    field,
    document_type: documentType,
    storage_ref: is(String, value) ? value : value.storageRef,
    meta: is(String, value) ? null : value.meta,
  })

  return data
}

const deletePendingDocument = async (documentId: string | number) => {
  const { data } = await apiClient.delete(
    `/registrations/document/${documentId}`
  )

  return data.data
}

const validateReferralCode = async ({
  referralCode,
  registrationId,
}: {
  referralCode?: string
  registrationId?: string
}) => {
  const { data } = await apiClient.get<{
    valid: boolean
  }>('/driver-referral-validate-rc', {
    params: {
      referral_code: referralCode,
      registration_id: registrationId,
    },
  })

  return data
}

export const registrationApi = {
  get,
  create,
  getDriverLead,
  invalidateFirstTimeLoginCode,
  patch,
  updateDocuments,
  referralInfo,
  submitPendingDocument,
  deletePendingDocument,
  validateReferralCode,
}
