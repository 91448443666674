import { RemoteConfigKey } from '@/v1/constants/remoteConfigKey'
import { MMPEvents } from '@/v1/types/mmpEvents'
import { getMmpDeviceType } from '@/v1/utils'
import { useRemoteConfig } from '@v1/hooks'
import { isNil } from 'ramda'
import { useCallback } from 'react'

import { AppsflyerInfo } from '../types/appsFlyer'
import { useRegion } from './useRegion'
import { useRegistrationConfig, useRegistrationInfo, useVehicleTypes } from '.'
import { envConfig } from '../constants'

export const useAppsflyer = () => {
  const { should } = useRemoteConfig()
  const isAppsFlyerEventTrackingEnabled = should(
    RemoteConfigKey.USE_APPSFLYER_EVENT_TRACKING
  )

  const { marketId, hasCityId, cityId } = useRegion()
  const { registrationInfo } = useRegistrationInfo()
  const { registrationConfig } = useRegistrationConfig()
  const { data: vehicleTypes = [] } = useVehicleTypes(cityId)

  const isAppsFlyerInitialized = () =>
    // eslint-disable-next-line new-cap
    !isNil(window.AF_SDK) &&
    window.AF_SDK.initPluginsConfig.pba.webAppId ===
      envConfig.VITE_APP_APPSFLYER_WEB_APP_ID
      ? true
      : false

  const getVehicleTypeName = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.label,
    [vehicleTypes]
  )

  const getCityName = useCallback(
    (cityIdParam: number | string) => {
      const cityObject = (registrationConfig?.city_list || []).find(
        city => city?.id.toString() === cityIdParam.toString()
      )

      return cityObject?.name_en || cityObject?.name
    },
    [registrationConfig]
  )

  const getVehicleTypeWheelType = useCallback(
    (vehicleId: number | string) =>
      vehicleTypes.find(type => type.value.toString() === vehicleId.toString())
        ?.wheel_type || '',
    [vehicleTypes]
  )

  const trackAppsflyer = useCallback(
    async ({ eventName, addedInfo }: AppsflyerInfo) => {
      if (isAppsFlyerEventTrackingEnabled && isAppsFlyerInitialized()) {
        try {
          const eventValue = {
            country_id: marketId,
            device_type: getMmpDeviceType(),
            city: getCityName(hasCityId ? Number(cityId) : 0),
            city_id: hasCityId ? cityId : undefined,
            registration_id: registrationInfo?.registration_id,
            vehicle_type_id: registrationInfo?.vehicle_type_id,
            vehicle_type_name: getVehicleTypeName(
              registrationInfo?.vehicle_type_id || 0
            ),
            language: registrationInfo?.language,
            user_agent: window.navigator.userAgent,
            ...addedInfo,
          }

          // support adding wheel_type to these certain events, see PRD https://huolala.feishu.cn/wiki/XgPJwB4aBiB6AukdUEFcWuyNnce
          const isWheelTypeEvent =
            eventName === MMPEvents.REGISTER_COMPLETED ||
            eventName === MMPEvents.REGISTER_LEAD_VERIFIED

          if (isWheelTypeEvent) {
            // call tracking with wheel_type
            // eslint-disable-next-line new-cap
            window.AF('pba', 'event', {
              eventType: 'EVENT',
              eventName: `${eventName}_${getVehicleTypeWheelType(
                registrationInfo?.vehicle_type_id || 0
              )}`,
              eventValue,
            })
          }

          // eslint-disable-next-line new-cap
          window.AF('pba', 'event', {
            eventType: 'EVENT',
            eventName: isWheelTypeEvent ? `${eventName}` : eventName,
            eventValue,
          })
        } catch (e) {
          console.error('Error appsflyer pba event', e)
        }
      } else {
        return
      }
    },
    [
      isAppsFlyerEventTrackingEnabled,
      marketId,
      cityId,
      hasCityId,
      registrationInfo,
      getVehicleTypeName,
      getCityName,
      getVehicleTypeWheelType,
    ]
  )

  return { trackAppsflyer }
}
