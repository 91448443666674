// Map country id to standard country code
export const countryCodeMap: Record<string, string> = {
  '10000': 'IN',
  '20000': 'BR',
  '30000': 'SG',
  '40000': 'MY',
  '50000': 'PH',
  '60000': 'MX',
  '70000': 'US',
  '80000': 'TW',
  '90000': 'HK',
  '100000': 'VN',
  '110000': 'TH',
  '120000': 'ID',
  '150000': 'JP',
  '160000': 'TR',
}
