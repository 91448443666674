import * as Sentry from '@sentry/react'
import { Path, envConfig } from '@v1/constants'
import { ApiErrors } from '@v1/constants/errors'
import { LocalStorageKey } from '@v1/constants/localStorageKey'
import { handleError } from '@v1/utils'
import { browserHistory } from '@v1/utils/browserHistory'
import { isNetworkError } from '@v1/utils/isNetworkError'
import { registrationInterceptor } from '@v1/utils/registrationInterceptor'
import { utmInterceptor } from '@v1/utils/utmInterceptor'
import axios, { AxiosHeaders } from 'axios'
import { safeParse } from 'secure-json-parse'

import {
  DriverCRMWebAPIError,
  DriverCRMWebAPIInvalidParamError,
  DriverCRMWebAPIOtpExceedLimitError,
} from './errors'

export const apiClient = axios.create({
  baseURL: String(envConfig.VITE_APP_DCRM_API_BASE_URL || ''),
  withCredentials: true,
})

apiClient.interceptors.request.use(utmInterceptor)
apiClient.interceptors.request.use(registrationInterceptor)

apiClient.interceptors.request.use(config => {
  const tokenJson = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN)
  const token = tokenJson && safeParse(tokenJson)

  const marketIdJson = localStorage.getItem(LocalStorageKey.MARKET_ID)
  const hcountry = marketIdJson && safeParse(marketIdJson)

  const hlangJson = localStorage.getItem(LocalStorageKey.HLANG)
  const hlang = hlangJson && safeParse(hlangJson)

  // eslint-disable-next-line fp/no-mutation
  config.headers = {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  } as AxiosHeaders

  // eslint-disable-next-line fp/no-mutation
  config.params = {
    ...(hcountry ? { hcountry } : {}),
    ...(hlang ? { hlang } : {}),
    ...config.params,
  }

  return config
})

apiClient.interceptors.response.use(
  response => response,
  error => {
    const { response } = error
    if (response) {
      switch (response.status) {
        case 401:
        case 403:
          // When personal driver register fleet or fleet driver register personal
          if (
            [
              ApiErrors.FLEET_REGISTRATION_MISMATCH_INVITATION,
              ApiErrors.REGISTRATION_DOES_NOT_MATCH_TYPE,
            ].includes(response.data.code)
          ) {
            throw new DriverCRMWebAPIError({
              message: response.data.message,
              code: response.data.code,
              details: response.data.details,
            })
          } else {
            localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN)
            browserHistory.replace(Path.LANDING)

            throw new DriverCRMWebAPIError({
              message: response.data.message,
              code: response.data.code,
              details: response.data.details,
              shouldNotifySentry: false,
            })
          }

        case 400:
        case 404:
        case 429:
          if (response.data.code === ApiErrors.EXCEED_LIMIT) {
            throw new DriverCRMWebAPIOtpExceedLimitError({
              message: response.data.message,
              code: response.data.code,
              details: response.data.details,
              shouldNotifySentry: false,
            })
          }

          throw new DriverCRMWebAPIError({
            message: response.data.message,
            code: response.data.code,
            details: response.data.details,
          })
        case 422: {
          if (response.data.code === ApiErrors.INVALID_PARAMETER) {
            throw new DriverCRMWebAPIInvalidParamError({
              message: response.data.message,
              code: response.data.code,
              details: response.data.details,
            })
          }

          throw new DriverCRMWebAPIError({
            message: response.data.message,
            code: response.data.code,
            details: response.data.details,
          })
        }

        case 423: {
          throw new DriverCRMWebAPIError({
            message: response.data.message,
            code: response.data.code,
            details: response.data.details,
          })
        }

        default:
          if (!isNetworkError(error)) {
            Sentry.withScope(scope => {
              // group errors together based on their request and response
              scope.setFingerprint([
                error.request.method,
                response?.config?.method,
                String(error.status),
              ])

              handleError(error)
            })
          }

          break
      }
    }

    return Promise.reject(error)
  }
)
