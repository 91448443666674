export enum QueryStringKey {
  FLEET_INV_CODE = 'invitation_code',
  HLANG = 'hlang',
  MARKET_ID = 'market_id',
  TOKEN = 'token',
  DEVICE_ID = 'device_id',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
  UTM_CAMPAIGN_TYPE = 'utm_campaign_type',
  UTM_ADNAME = 'utm_adname',
  IFRAME = 'iframe',
  HTZONE = 'htzone',
  CLIENT_TYPE = 'client_type',
  HCOUNTRY = 'hcountry',
  CITY_ID = 'city_id',
  DRIVER_MD5 = 'driver_md5',
  OS_TYPE = 'os_type',
  UA = 'ua',
  APP_ID = 'app_id',
  APP_VERSION = 'version',
  REVISION = 'revision',
  // stone template code
  STONE_TEMPLATE_CODE = 'code',
  // driver referral code (invitation code)
  DRIVER_REFER_CODE = 'ic',
  REFER_PLATFORM = 'refer_platform',
  // DRD (generic referral code)
  DRIVER_REFERRAL_CODE = 'rc',
  // gclid for google ads
  GOOGLE_CLICK_ID = 'gclid',
  MISSED_CALL_OTP = 'missed_call_otp',
}
