import { ClientFormConfigs, FormConfigs } from '@v1/types'

import { apiClient } from './apiClient'

const transformForm = (formConfigs: FormConfigs): ClientFormConfigs => ({
  ...(formConfigs.basic_info && {
    basicInfo: {
      canSkip: formConfigs.basic_info.can_skip,
      fields: formConfigs.basic_info.fields,
    },
  }),
  ...(formConfigs.personal_info && {
    personalInfo: {
      canSkip: formConfigs.personal_info.can_skip,
      fields: formConfigs.personal_info.fields,
    },
  }),
  ...(formConfigs.vehicle_info && {
    vehicleInfo: {
      canSkip: formConfigs.vehicle_info.can_skip,
      fields: formConfigs.vehicle_info.fields,
    },
  }),
  ...(formConfigs.additional_info && {
    additionalInfo: {
      canSkip: formConfigs.additional_info.can_skip,
      fields: formConfigs.additional_info.fields,
    },
  }),
})

const get = async ({
  cityId,
  vehicleTypeId,
  isFleet,
}: {
  cityId?: number | string
  vehicleTypeId?: string | number
  isFleet: boolean
}) => {
  const { data } = await apiClient.get<FormConfigs>('/form-configs', {
    params: {
      city_id: cityId,
      vehicle_type_id: vehicleTypeId,
      reg_flow_type: isFleet ? 'fleet' : 'personal',
    },
  })

  return transformForm(data)
}

export const formConfigsApi = {
  get,
}
