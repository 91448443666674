import { isInDriverAppWebView } from '@hll/driver-webview-bridge-js'
import { useQueryClient } from '@tanstack/react-query'
import { CacheKey, LocalStorageKey } from '@v1/constants'
import { Path } from '@v1/constants/path'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import { driverRegSdkInstance } from '@v1/utils/nativeBridge'
import constate from 'constate'
import { isNil } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocalStorage, useUpdateEffect } from 'react-use'

import { useQueryParam } from './useQueryParam'
import { useLocalStorageRT } from '.'

export const [AuthProvider, useAuth] = constate(() => {
  const history = useHistory()
  const params = useQueryParam()
  const [isRegistrationInitialized, setRegistrationInitialized] =
    useState<boolean>()

  const [token, setToken, removeToken] = useLocalStorageRT<string>(
    LocalStorageKey.ACCESS_TOKEN
  )

  const [, , removeStoredFormValues] = useLocalStorage<string>(
    LocalStorageKey.STORED_FORM_VALUES
  )

  const [, , removeVirtualTrainingCompletionAcknowledgedPhones] =
    useLocalStorage<string>(LocalStorageKey.VT_ACKNOWLEDGED_BY_PHONE)

  const [, , removeOnsiteTrainingScheduleAcknowledgedPhones] =
    useLocalStorage<string>(LocalStorageKey.OT_ACKNOWLEDGED_BY_PHONE)

  const [, , removeOcrRejectionCount] = useLocalStorage(
    LocalStorageKey.OCR_REJECTIONS
  )

  const queryClient = useQueryClient()
  const queryToken = params.get(QueryStringKey.TOKEN) || undefined

  const logout = useCallback(() => {
    removeToken()
    removeStoredFormValues()
    removeVirtualTrainingCompletionAcknowledgedPhones()
    removeOnsiteTrainingScheduleAcknowledgedPhones()
    removeOcrRejectionCount()
    setRegistrationInitialized(false)
    queryClient.clear()
    history.replace(Path.LANDING)
  }, [
    queryClient,
    history,
    removeToken,
    removeStoredFormValues,
    removeVirtualTrainingCompletionAcknowledgedPhones,
    removeOnsiteTrainingScheduleAcknowledgedPhones,
    removeOcrRejectionCount,
  ])

  // handle token from url query
  useEffect(() => {
    if (!isNil(queryToken)) {
      setToken(queryToken)
    }
  }, [queryToken, setToken])

  // token change, refetch registration
  useUpdateEffect(() => {
    if (!token) return
    queryClient.invalidateQueries([CacheKey.REGISTRATION])
  }, [token])

  useEffect(() => {
    if (!isInDriverAppWebView()) return
    driverRegSdkInstance.setShouldAutoShowRegistration(Boolean(token))
  }, [token])

  return {
    isLoggedIn: Boolean(token),
    logout,
    login: setToken,
    token,
    isRegistrationInitialized,
    setRegistrationInitialized,
  }
})
