import { PhoneNumber } from '@v1/types'

import { apiClient } from './apiClient'

export type FleetInfo = {
  fleet_id: number
  fleet_name: string
  driver_name: string
  owner_name: string
  national_number: string
  area_code: string
  phone_number: PhoneNumber
  country_id: number
  city_id: number
  vehicle_type_id: number
}

const getInfoByInvitationCode = async ({
  invitationCode,
}: {
  invitationCode: string | number
}) => {
  const { data } = await apiClient.get<FleetInfo>('/fleet-invitations', {
    params: {
      invitation_code: invitationCode,
    },
  })

  return data
}

export const fleetApi = {
  getInfoByInvitationCode,
}
