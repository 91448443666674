export enum DataCentre {
  BR = 'br',
  SEA = 'sg',
  IN = 'in',
}

export const DataCentrePrefixMap: Record<DataCentre, string> = {
  br: 'SAO',
  in: 'BOM',
  sg: 'SIN',
}

export enum ViteAppIDC {
  SG_LOCAL = 'sg-local',
  SG_STG = 'sg-stg',
  SG_PRE = 'sg-pre',
  SG_PROD = 'sg-prd',
  IN_PROD = 'in-prd',
  BR_PROD = 'br-prd',
  SG_PRE_UNCLED = 'sg-pre-uncled',
  SG_STG_UNCLED = 'sg-stg-uncled',
}
