import { QueryStringKey } from '@v1/constants/queryStringKey'
import { InternalAxiosRequestConfig } from 'axios'

import { shallowRemoveEmptyProps } from './removeEmptyProps'

export const UTM_KEYS: string[] = [
  QueryStringKey.UTM_SOURCE,
  QueryStringKey.UTM_MEDIUM,
  QueryStringKey.UTM_CAMPAIGN,
  QueryStringKey.UTM_TERM,
  QueryStringKey.UTM_CONTENT,
  QueryStringKey.UTM_CAMPAIGN_TYPE,
  QueryStringKey.UTM_ADNAME,
]

export const utmInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig<any> => {
  const urlSearchParams = new URLSearchParams(window.location.search)

  const utmParams = shallowRemoveEmptyProps(
    Object.fromEntries(
      [...urlSearchParams].filter(([key]) => UTM_KEYS.includes(key))
    )
  )

  // eslint-disable-next-line fp/no-mutation
  config.params = {
    ...utmParams,
    ...config.params,
  }

  return config
}
