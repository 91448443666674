import errorImage from '@themedImages/error.svg'
import { ImageUrl } from '@v1/types'
import constate from 'constate'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

type ModalOptions = {
  imgSrc: ImageUrl
  title: string
  content: string
  buttonLabel?: string
  hasCloseLabel?: boolean
  onDismiss?: () => void
}

export const [UseModalProvider, useModal] = constate(() => {
  const [options, setOptions] = useState<ModalOptions>()
  const [isDisplay, toggleDisplay] = useState(false)
  const { t } = useTranslation()
  const open = useCallback(
    ({
      imgSrc,
      title,
      content,
      buttonLabel,
      onDismiss,
      hasCloseLabel,
    }: ModalOptions) => {
      setOptions({
        imgSrc,
        title,
        content,
        buttonLabel,
        hasCloseLabel,
        onDismiss,
      })

      toggleDisplay(true)
    },
    [setOptions, toggleDisplay]
  )

  const close = () => {
    toggleDisplay(false)
    options?.onDismiss?.()
  }

  const openConnectionLost = useCallback(
    (modalOptions?: Partial<ModalOptions>) => {
      const {
        imgSrc = errorImage,
        title = String(t('Error.title_connection_lost')),
        content = String(t('Error.content_connection_lost')),
        buttonLabel = String(t('Actions.got_it')),
        hasCloseLabel = false,
        onDismiss = () => {
          toggleDisplay(false)
        },
      } = modalOptions || {}

      open({
        imgSrc,
        title,
        content,
        buttonLabel,
        onDismiss,
        hasCloseLabel,
      })
    },
    [open, t]
  )

  const openUnexpected = useCallback(
    (modalOptions?: Partial<ModalOptions>) => {
      const {
        imgSrc = errorImage,
        title = String(t('Error.title_upexpected')),
        content = String(t('Error.content_unexpected')),
        buttonLabel = String(t('Actions.got_it')),
        hasCloseLabel = false,
        onDismiss = () => {
          toggleDisplay(false)
        },
      } = modalOptions || {}

      open({
        imgSrc,
        title,
        content,
        buttonLabel,
        onDismiss,
        hasCloseLabel,
      })
    },
    [open, t]
  )

  return {
    openConnectionLost,
    openUnexpected,
    open,
    close,
    reset: () => setOptions(undefined),
    options,
    isDisplay,
  }
})
