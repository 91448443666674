export enum LocalStorageKey {
  ACCESS_TOKEN = 'access_token',
  PHONE_NUMBER = 'phone_number',
  MARKET_ID = 'market_id',
  HLANG = 'hlang',
  REJECTION_ACKNOWLEDGED_BY_PHONE = 'rejection_acknowledged_phones',
  STORED_FORM_VALUES = 'stored_form_values',
  VT_ACKNOWLEDGED_BY_PHONE = 'vt_acknowledged_by_phone',
  OT_ACKNOWLEDGED_BY_PHONE = 'ot_acknowledged_by_phone',
  OCR_REJECTIONS = 'ocr_rejections',
  AF_USER_ID = 'af_user_id',
}
