import { useQuery } from '@tanstack/react-query'
import { CacheKey, Path } from '@v1/constants'
import { onsiteTrainingApi } from '@v1/services/dcrm'
import { handleError } from '@v1/utils'
import dayjs from 'dayjs'
import { isEmpty } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'

import { useAuth } from './useAuth'
import { useRegistrationInfo, useSetLoading } from '.'

export const useOnsiteTraining = () => {
  const { isLoggedIn, isRegistrationInitialized } = useAuth()
  const routeHistory = useHistory()
  const { registrationInfo } = useRegistrationInfo()
  const { vehicle_type_id, city_id, status } = registrationInfo || {}

  const isOnsiteTrainingEnabled = Boolean(
    isLoggedIn && isRegistrationInitialized
  )

  const {
    data: sessions = [],
    isLoading: isLoadingOnsiteTrainings,
    isError: isOnsiteTrainingError,
  } = useQuery(
    // onsiteTrainings dependent on status, vehicle_type_id and city_id in backend, trigger refetch on this value updated
    [CacheKey.ONSITE_TRAINING, status, vehicle_type_id, city_id],
    () => onsiteTrainingApi.list(),
    {
      enabled: isOnsiteTrainingEnabled,
      onError: e => {
        handleError(e)
        routeHistory.push(Path.ERROR)
      },
    }
  )

  const setLoading = useSetLoading()
  const isTriningCenterEnabled = Boolean(
    isLoggedIn &&
      !isEmpty(sessions) &&
      !(isOnsiteTrainingEnabled && isLoadingOnsiteTrainings) &&
      isRegistrationInitialized
  )

  const { data: centers = [], isLoading: isLoadingTrainingCenters } = useQuery(
    [CacheKey.ONSITE_TRAINING_CENTERS],
    onsiteTrainingApi.listCenters,
    {
      enabled: isTriningCenterEnabled,
      onError: e => {
        handleError(e)
        routeHistory.push(Path.ERROR)
      },
    }
  )

  const { data: enrollments } = useQuery(
    [CacheKey.ONSITE_TRAINING_ENROLLMENTS],
    onsiteTrainingApi.getEnrollments,
    {
      enabled: Boolean(isLoggedIn && isRegistrationInitialized),
    }
  )

  const option = useMemo(() => {
    const totalDuration = isEmpty(sessions)
      ? 0
      : sessions
          .map(training =>
            dayjs(training.end_at).diff(training.start_at, 'minutes', false)
          )
          .reduce((accumulation, current) => accumulation + current, 0)

    const avgDuration = Math.floor(totalDuration / sessions.length)

    return {
      duration: avgDuration,
    }
  }, [sessions])

  const availableCenters = useMemo(
    () =>
      centers.filter(({ id }) =>
        sessions.some(({ training_center_id }) => id === training_center_id)
      ),
    [centers, sessions]
  )

  useEffect(() => {
    setLoading({
      isLoadingTrainingCenters:
        isTriningCenterEnabled && isLoadingTrainingCenters,
      isLoadingOnsiteTrainings:
        isOnsiteTrainingEnabled && isLoadingOnsiteTrainings,
    })
  }, [
    isTriningCenterEnabled,
    isLoadingTrainingCenters,
    isOnsiteTrainingEnabled,
    isLoadingOnsiteTrainings,
    setLoading,
  ])

  return {
    isLoading: isOnsiteTrainingEnabled && isLoadingOnsiteTrainings,
    isAvailable: !isEmpty(sessions),
    isError: isOnsiteTrainingError,
    option,
    data: sessions,
    centers: availableCenters,
    enrollments,
  }
}
