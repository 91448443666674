import { LocalStorageKey, envConfig } from '@v1/constants'
import { QueryStringKey } from '@v1/constants/queryStringKey'
import constate from 'constate'
import dayjs from 'dayjs'
import { last } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

import { toHlang, toI18nLocal } from './../utils/normaliseLocal'
import { useQueryParam, useRegion } from '.'

const fallbackLanguage =
  envConfig.VITE_APP_THEME === 'uncleD' ? 'en_in' : 'en_hk'

const dayjsModules = import.meta.glob('/node_modules/dayjs/esm/locale/*.js')
const dayjsLocalList = Object.keys(dayjsModules).map(path => {
  const fileName = last(path.split('/'))
  const [locale] = (fileName || '').split('.')
  return locale
})

export const [UseI18nProvider, useI18n] = constate(() => {
  const { i18n } = useTranslation()
  const { languages, isLoadingRegionConfig } = useRegion()
  const query = useQueryParam()
  const queryLanguage = query.get(QueryStringKey.HLANG)
  const [initialized, setInitialized] = useState(false)

  const [hlang = fallbackLanguage, setLanguage] = useLocalStorage(
    LocalStorageKey.HLANG,
    fallbackLanguage
  )

  useEffect(() => {
    if (queryLanguage) {
      const localDayjsLocal = async () => {
        const dayJsLangString = toI18nLocal(queryLanguage)?.toLowerCase()

        const localItem = dayjsLocalList.find(key => key === dayJsLangString)

        const localItemWithFallback =
          localItem ||
          dayjsLocalList.find(
            key =>
              // if there is no key(ie en-hk), fallback to the prefix(en)
              key === dayJsLangString?.split('-')[0]
          )

        if (!localItemWithFallback) return

        const matchedModule =
          dayjsModules[
            `/node_modules/dayjs/esm/locale/${localItemWithFallback}.js`
          ]

        await matchedModule?.()

        dayjs.locale(localItemWithFallback)
      }

      localDayjsLocal()

      i18n.changeLanguage(toI18nLocal(queryLanguage))

      setLanguage(toHlang(queryLanguage))
    }
  }, [i18n, queryLanguage, setLanguage])

  useEffect(() => {
    if (isLoadingRegionConfig) return
    setInitialized(false)

    const isIncluded = languages.some(
      ({ id }) => id.toLowerCase() === hlang.toLowerCase()
    )

    const language = languages[0]?.id
    if (!isIncluded && language && !queryLanguage) {
      setLanguage(toHlang(language))
    }

    setInitialized(true)
  }, [queryLanguage, languages, hlang, setLanguage, isLoadingRegionConfig])

  useEffect(() => {
    if (initialized) i18n.changeLanguage(toI18nLocal(hlang))
  }, [initialized, hlang, i18n])

  return {
    language: toI18nLocal(hlang),
    setLanguage: (key: string) => {
      setLanguage(toHlang(key))
    },
    initialized: initialized && i18n.isInitialized,
    hlang,
  }
})
