import { ApiErrors } from '@v1/constants/errors'

export class DriverCRMWebAPIError extends Error {
  code: ApiErrors | null
  details: any
  override message: string
  isSentryError: boolean
  shouldNotifySentry: boolean

  constructor({
    message,
    code,
    details = [],
    isSentryError = false,
    shouldNotifySentry = true,
  }: {
    code: ApiErrors | null
    message: string
    details?: any
    isSentryError?: boolean
    shouldNotifySentry?: boolean
  }) {
    super(message)
    // eslint-disable-next-line fp/no-mutation
    this.message = message
    // eslint-disable-next-line fp/no-mutation
    this.code = code
    // eslint-disable-next-line fp/no-mutation
    this.details = details
    // eslint-disable-next-line fp/no-mutation
    this.isSentryError = isSentryError
    // eslint-disable-next-line fp/no-mutation
    this.shouldNotifySentry = shouldNotifySentry
  }
}
export const isDriverCRMWebAPIError = (e: any): e is DriverCRMWebAPIError =>
  e instanceof DriverCRMWebAPIError
