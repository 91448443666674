import { RegionConfig } from './../../types/regionConfig'
import { apiClient } from './apiClient'

const get = async ({ deviceId }: { deviceId?: string }) => {
  const { data } = await apiClient.get<{
    data: RegionConfig[]
    ret: number
    msg: string
  }>('/index.php', {
    params: {
      ...(deviceId ? { device_id: deviceId } : {}),
    },
  })

  return data.data
}

export const regionConfigApi = { get }
