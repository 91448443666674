import constate from 'constate'
import { FirebaseError } from 'firebase/app'
import { fetchAndActivate, getString } from 'firebase/remote-config'
import { has } from 'ramda'
import { useCallback, useState } from 'react'
import { useMount } from 'react-use'

import { RemoteConfigKey } from './../constants/remoteConfigKey'
import { remoteConfig } from './../utils/firebase'
import { handleError } from '../utils'
import { useRegion } from './useRegion'
import { envConfig, legacyEnvMap } from '../constants'

export type RemoteConfig = {
  [env: string]:
    | boolean
    | {
        enabled: boolean
        cities?: {
          [cityId: string]: boolean
        }
        markets?: {
          [marketId: string]: boolean
        }
        default: boolean
      }
}

export const [UseRemoteConfigProvider, useRemoteConfig] = constate(() => {
  const [initialized, setInitialized] = useState(false)
  const { cityId, marketId } = useRegion()

  useMount(async () => {
    try {
      await fetchAndActivate(remoteConfig)
      setInitialized(true)
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleError(error)
      } else {
        throw error
      }
    }
  })

  const should = useCallback(
    (key: RemoteConfigKey): boolean => {
      if (!initialized) return false
      try {
        const value = getString(remoteConfig, key)
        const parsedValue: RemoteConfig = JSON.parse(value)

        const mappedEnv = legacyEnvMap[envConfig.VITE_APP_IDC]

        if (!mappedEnv) return false

        const mappedEnvConfig = parsedValue[mappedEnv] || false

        if (typeof mappedEnvConfig === 'boolean') return mappedEnvConfig
        if (!mappedEnvConfig.enabled) return false
        if (
          mappedEnvConfig.cities &&
          has(String(cityId), mappedEnvConfig.cities)
        ) {
          return mappedEnvConfig.cities[cityId] || false
        }

        if (
          mappedEnvConfig.markets &&
          has(String(marketId), mappedEnvConfig.markets)
        ) {
          return mappedEnvConfig.markets[marketId] || false
        }

        return mappedEnvConfig.default || false
      } catch (error) {
        // no need to tell sentry
        return false
      }
    },
    [cityId, marketId, initialized]
  )

  return { initialized, should }
})
